import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import debounce from 'lodash/debounce';

import './shop.scss';
import header_img from '../../image/header-logo.webp';
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import useStructuredData from '../../hooks/useStructuredData';

// Компонент EmptyState
const EmptyState = ({ message, actionText, onAction }) => {
  return (
    <div className="empty-state">
      <h3>{message}</h3>
      {actionText && onAction && (
        <button onClick={onAction} className="empty-state__button" aria-label={actionText}>
        {actionText}
      </button>
      )}
    </div>
  );
};

// Компонент фільтрів
const FilterPanel = ({
  filterOptions,
  filterValues,
  onFilterChange,
  onReset,
  searchValue,
  onSearchChange,
  onSearchSubmit
}) => {
  const { sizes, types, colors } = filterOptions;
  const { selectSort, selectSize, selectType, selectSeason, selectColor } = filterValues;
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

  return (
    <div className="filter">
      <div className="filter__container">
        <button className="filter__mobile-toggle" onClick={toggleFilter} aria-label="Відкрити/Закрити фільтри">
          <span>Фільтри</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
          </svg>
        </button>

        <div className={`filter__wrapper ${isFilterOpen ? 'filter__wrapper--open' : ''}`}>
          <div className="filter__top">
            <select
              name="sort"
              className="filter__select"
              onChange={(e) => onFilterChange('selectSort', e.target.value)}
              value={selectSort}
              aria-label="Сортувати товари"
            >
              <option value="default" disabled hidden>Сортувати за</option>
              <option value="popular">Спочатку популярні</option>
              <option value="unpopular">Спочатку не популярні</option>
              <option value="rich">Спочатку дорогі</option>
              <option value="poor">Спочатку дешеві</option>
            </select>

            {sizes.length > 0 && (
              <select
                name="size"
                className="filter__select"
                onChange={(e) => onFilterChange('selectSize', e.target.value)}
                value={selectSize}
                aria-label="Фільтрувати за розміром"
              >
                <option value="default" disabled hidden>Розмір</option>
                {sizes.map((size, index) => (
                  <option key={index} value={size}>{size}</option>
                ))}
              </select>
            )}

            {types.length > 0 && (
              <select
                name="type"
                className="filter__select"
                onChange={(e) => onFilterChange('selectType', e.target.value)}
                value={selectType}
                aria-label="Фільтрувати за типом"
              >
                <option value="default" disabled hidden>Тип</option>
                {types.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
            )}

            <select
              name="season"
              className="filter__select"
              onChange={(e) => onFilterChange('selectSeason', e.target.value)}
              value={selectSeason}
              aria-label="Фільтрувати за сезоном"
            >
              <option value="default" disabled hidden>Сезон</option>
              <option value="summer">Літо</option>
              <option value="winter">Зима</option>
              <option value="spring">Весна-осінь</option>
            </select>

            {colors.length > 0 && (
              <select
                name="color"
                className="filter__select"
                onChange={(e) => onFilterChange('selectColor', e.target.value)}
                value={selectColor}
                aria-label="Фільтрувати за кольором"
              >
                <option value="default" disabled hidden>Колір</option>
                {colors.map((color, index) => (
                  <option key={index} value={color}>{color}</option>
                ))}
              </select>
            )}
          </div>

          <div className="filter__bottom">
            <button
              className="filter__reset"
              onClick={onReset}
              aria-label="Скинути всі фільтри"
            >
              Скинути
            </button>
          </div>
        </div>

        <div className="filter__search">
          <input
            type="text"
            className="filter__search-input"
            placeholder="Пошук товарів"
            value={searchValue}
            onChange={onSearchChange}
            aria-label="Пошук товарів за назвою"
          />
          <button
            className="filter__search-btn"
            onClick={onSearchSubmit}
            aria-label="Пошук товарів"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

// Компонент для відображення активних фільтрів
const ActiveFilters = ({ filters, filterOptions, onReset, onRemoveSingleFilter }) => {
  const { selectSize, selectType, selectSeason, selectColor } = filters;

  // Перевіряємо, чи є активні фільтри
  const hasActiveFilters = selectSize !== "default" ||
    selectType !== "default" ||
    selectSeason !== "default" ||
    selectColor !== "default";

  if (!hasActiveFilters) return null;

  // Функція для отримання назви сезону
  const getSeasonName = (season) => {
    switch (season) {
      case 'summer': return 'Літо';
      case 'winter': return 'Зима';
      case 'spring': return 'Весна-осінь';
      default: return '';
    }
  };

  return (
    <div className="active-filters">
      <div className="active-filters__title">Активні фільтри:</div>
      <div className="active-filters__tags">
        {selectSize !== "default" && (
          <div className="active-filters__tag">
            Розмір: {selectSize}
            <button onClick={() => onRemoveSingleFilter('selectSize')} aria-label="Видалити фільтр розміру">×</button>
          </div>
        )}
        {selectType !== "default" && (
          <div className="active-filters__tag">
            Тип: {selectType}
            <button onClick={() => onRemoveSingleFilter('selectType')} aria-label="Видалити фільтр типу">×</button>
          </div>
        )}
        {selectSeason !== "default" && (
          <div className="active-filters__tag">
            Сезон: {getSeasonName(selectSeason)}
            <button onClick={() => onRemoveSingleFilter('selectSeason')} aria-label="Видалити фільтр сезону">×</button>
          </div>
        )}
        {selectColor !== "default" && (
          <div className="active-filters__tag">
            Колір: {selectColor}
            <button onClick={() => onRemoveSingleFilter('selectColor')} aria-label="Видалити фільтр кольору">×</button>
          </div>
        )}
        <button className="active-filters__clear" onClick={onReset} aria-label="Скинути всі фільтри">
          Скинути все
        </button>
      </div>
    </div>
  );
};

// Кастомний хук для фільтрації товарів
const useProductFilters = (products) => {
  const [filters, setFilters] = useState({
    selectSort: "default",
    selectSize: "default",
    selectType: "default",
    selectSeason: "default",
    selectColor: "default"
  });

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  // Мемоізація категорій фільтрів
  const filterOptions = useMemo(() => {
    if (!products) return { sizes: [], types: [], colors: [] };

    // Оптимізована обробка даних в один прохід
    const options = products.reduce((acc, product) => {
      // Додаємо розміри
      product.sizes.forEach(size => {
        if (!acc.sizes.includes(size)) {
          acc.sizes.push(size);
        }
      });

      // Додаємо тип
      if (!acc.types.includes(product.type)) {
        acc.types.push(product.type);
      }

      // Додаємо колір
      if (!acc.colors.includes(product.color)) {
        acc.colors.push(product.color);
      }

      return acc;
    }, { sizes: [], types: [], colors: [] });

    // Сортуємо масиви
    options.sizes.sort((a, b) => a - b);
    options.types.sort();
    options.colors.sort();

    return options;
  }, [products]);

  // Функція для оновлення окремого фільтра
  const updateFilter = useCallback((filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  }, []);

  // Функція для видалення одного фільтра
  const removeSingleFilter = useCallback((filterName) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: "default"
    }));
  }, []);

  // Функція для скидання фільтрів
  const resetFilters = useCallback(() => {
    setFilters({
      selectSort: "default",
      selectSize: "default",
      selectType: "default",
      selectSeason: "default",
      selectColor: "default"
    });
    setSearch("");
    setFilteredData(null);
  }, []);

  // Мемоізована функція фільтрації
  const applyFilters = useCallback(() => {
    if (!products) return null;

    let result = [...products];
    const { selectSize, selectType, selectSeason, selectColor, selectSort } = filters;

    // Фільтрація за розміром
    if (selectSize !== "default") {
      result = result.filter(item => item.sizes.includes(selectSize));
    }

    // Фільтрація за типом
    if (selectType !== "default") {
      result = result.filter(item => item.type === selectType);
    }

    // Фільтрація за сезоном
    if (selectSeason !== "default") {
      result = result.filter(item => item.season === selectSeason);
    }

    // Фільтрація за кольором
    if (selectColor !== "default") {
      result = result.filter(item => item.color === selectColor);
    }

    // Пошук за назвою
    if (search) {
      result = result.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Сортування
    if (selectSort !== "default") {
      if (selectSort === 'popular') {
        result.sort((a, b) => b.popular - a.popular);
      } else if (selectSort === 'unpopular') {
        result.sort((a, b) => a.popular - b.popular);
      } else if (selectSort === 'rich') {
        result.sort((a, b) => b.price - a.price);
      } else if (selectSort === 'poor') {
        result.sort((a, b) => a.price - b.price);
      }
    }

    return result;
  }, [products, filters, search]);

  // Функція для здійснення пошуку
  const searchProducts = useCallback((value) => {
    setSearch(value);
  }, []);

  // Оновлення filteredData при зміні фільтрів або пошуку
  useEffect(() => {
    setFilteredData(applyFilters());
  }, [applyFilters]);

  // Debounce для пошуку
  const debouncedSearch = useMemo(
    () => debounce(searchProducts, 300),
    [searchProducts]
  );

  return {
    filteredData,
    filterOptions,
    filters,
    search,
    updateFilter,
    resetFilters,
    searchProducts,
    debouncedSearch,
    removeSingleFilter
  };
};

// Компонент для відображення інформації про кількість товарів
const ProductCount = ({ count, totalCount }) => {
  return (
    <div className="product-count">
      Знайдено товарів: <strong>{count}</strong> {count !== totalCount && `(з ${totalCount})`}
    </div>
  );
};

//Хлібні крихти
const Breadcrumbs = () => {
  const breadcrumbsData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Головна",
        "item": "https://shoedream.com.ua/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Магазин",
        "item": "https://shoedream.com.ua/shop"
      }
    ]
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(breadcrumbsData)}
    </script>
  );
};

export const Shop = () => {
  const { posts } = useSelector(state => state.post);
  const products = posts.data || [];

  // Використовуємо кастомний хук для фільтрації
  const {
    filteredData,
    filterOptions,
    filters,
    search,
    updateFilter,
    resetFilters,
    searchProducts,
    debouncedSearch,
    removeSingleFilter
  } = useProductFilters(products);

  // Динамічне оновлення title в залежності від фільтрів
  const getPageTitle = () => {
    if (filters.selectType !== "default") {
      return `${filters.selectType} | Взуттєва Мрія`;
    }
    return "Взуттєва Мрія | Інтернет-магазин жіночого взуття - Купити недорого в Україні";
  };

  // Генеруємо структуровані дані для SEO
  const structuredData = useStructuredData(filteredData || products);

  // URL сторінки (поточний або з фільтрами)
  const currentUrl = window.location.href;

  // Мікророзмітка для окремого товару
  const generateProductSchema = (product) => {
    return {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": product.title,
      "image": product.image,
      "description": product.description,
      "brand": {
        "@type": "Brand",
        "name": "Взуттєва Мрія"
      },
      "offers": {
        "@type": "Offer",
        "url": `https://shoedream.com.ua/product/${product.id}`,
        "priceCurrency": "UAH",
        "price": product.price,
        "availability": "https://schema.org/InStock"
      }
    };
  };

  // Прокручуємо сторінку вгору при першому завантаженні
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Обробники подій
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    debouncedSearch(value);
    // Встановлюємо значення для відображення в input одразу, не чекаючи debounce
    searchProducts(value);
  };

  const handleSearchButtonClick = () => {
    // Примушуємо пошук без debounce при натисканні кнопки
    searchProducts(search);
  };

  // Мемоізуємо відображувані дані
  const displayData = useMemo(() => {
    return filteredData || products;
  }, [filteredData, products]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content="Купуйте стильне жіноче взуття в інтернет-магазині Взуттєва Мрія за вигідними цінами. Великий вибір кросівок, туфель, чобіт, ботильйонів, мокасин. Швидка доставка по Україні, повернення 14 днів." />
        <meta name="keywords" content="жіноче взуття, купити взуття, інтернет-магазин взуття, взуттєва мрія, кросівки жіночі, туфлі жіночі, чоботи жіночі, ботильйони жіночі, мокасини жіночі, сліпони жіночі, шльопанці жіночі, взуття Україна, взуття Київ" />
        <meta name="robots" content="index, follow" />

        {/* Canonical URL */}
        <link rel="canonical" href={currentUrl} />

        {/* Open Graph теги */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:title" content={getPageTitle()} />
        <meta property="og:description" content="Купуйте стильне жіноче взуття в інтернет-магазині Взуттєва Мрія за вигідними цінами." />
        <meta property="og:image" content={header_img} /> {/* Замініть URL_вашого_зображення на реальний URL */}
        <meta property="og:site_name" content="Взуттєва Мрія" />

        {/* Twitter Card теги */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getPageTitle()} />
        <meta name="twitter:description" content="Купуйте стильне жіноче взуття в інтернет-магазині Взуттєва Мрія за вигідними цінами." />
        <meta name="twitter:image" content={header_img} /> {/* Замініть URL_вашого_зображення на реальний URL */}

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="format-detection" content="telephone=no" />

        {/* Google Search Console */}
        <meta name="google-site-verification" content="код_верифікації" />

        {/* Структуровані дані */}
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>

      {/*Хлібні крихти*/}
      <Breadcrumbs />

      <Nav />

      <header className="header">
        <div className="container">
          <div className="header__wrapper">
            <div className="header__content">
              <h1 className="header__title">Взуттєва мрія</h1>
              <p className="header__subtitle">Нехай кожен крок буде особливим</p>
              <p className="header__text">Життя краще в комфортному взутті</p>
              <a href="#products" className="header__button" aria-label="Перейти до каталогу">Дивитися каталог</a>
            </div>
            <div className="header__image-container">
              <img src={header_img} alt="Взуттєва мрія - логотип" className="header__img"/>
            </div>
          </div>
        </div>
      </header>

      <div id="products" className="goods">
        <div className="container">
          {/* Компонент фільтрів */}
          <FilterPanel
            filterOptions={filterOptions}
            filterValues={filters}
            onFilterChange={updateFilter}
            onReset={resetFilters}
            searchValue={search}
            onSearchChange={handleSearchInputChange}
            onSearchSubmit={handleSearchButtonClick}
          />

          {/* Компонент активних фільтрів */}
          <ActiveFilters
            filters={filters}
            filterOptions={filterOptions}
            onReset={resetFilters}
            onRemoveSingleFilter={removeSingleFilter}
          />

          {/* Відображення кількості товарів */}
          {posts.status !== 'loading' && products.length > 0 && (
            <ProductCount count={displayData.length} totalCount={products.length} />
          )}

          {/* Стан завантаження */}
          {posts.status === 'loading' && <Spiner />}

          {/* Відображення товарів або порожнього стану */}
          {posts.status !== 'loading' && products.length > 0 && (
            <>
              {displayData.length > 0 ? (
                <div className="goods__all" ref={el => { }}>
                  <Pagination
                    items={displayData}
                    renderImage={(src, item) => (
                      <>
                        <img
                          src={src}
                          alt={`${item.title} ${item.color} ${item.type}`}
                          loading="lazy"
                        />
                        <script type="application/ld+json">
                          {JSON.stringify(generateProductSchema(item))}
                        </script>
                      </>
                    )}
                  />
                </div>
              ) : (
                <EmptyState
                  message="Товари не знайдено за вашим запитом"
                  actionText="Скинути фільтри"
                  onAction={resetFilters}
                />
              )}
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};