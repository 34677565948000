import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import './home.scss';
import header_img from '../../image/header-logo.webp';
import { Nav, Footer, Product, ProductSlider } from '../../components/';

// Функція для виявлення підтримки WebP
const supportsWebp = () => {
    if (typeof window === 'undefined' || !window.createImageBitmap) {
        return Promise.resolve(false);
    }
    return new Promise(resolve => {
        const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
        fetch(webpData).then(response => response.blob()).then(blob => {
            window.createImageBitmap(blob).then(() => resolve(true), () => resolve(false));
        });
    });
};

export const Home = () => {
    const [productsToShow, setProductsToShow] = useState(20);
    const { posts } = useSelector(state => state.post);
    const popular = posts?.data || [];
    const sale = popular.filter(post => post.discount).slice(0, 8);
    const [webpSupported, setWebpSupported] = useState(null);
    const headerImageRef = useRef(null);


    useEffect(() => {
        // Перевірка підтримки WebP при монтуванні компонента
        supportsWebp().then(supported => {
            setWebpSupported(supported);
        });

        window.scrollTo(0, 0);
        handleWindowResize();

        const handleResize = () => {
            handleWindowResize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleWindowResize = () => {
        const width = window.innerWidth;
        let newProductsToShow;

        if (width < 576) {
            newProductsToShow = 8;
        } else if (width < 768) {
            newProductsToShow = 12;
        } else if (width < 992) {
            newProductsToShow = 16;
        } else {
            newProductsToShow = 20;
        }

        setProductsToShow(newProductsToShow);
    };

    const sortedPopular = [...popular].sort((a, b) => b.popular - a.popular).slice(0, productsToShow);

    return (
        <>
            <Helmet>
                <title>Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні</title>
                <meta name="description" content="Купуйте жіноче взуття в інтернет-магазині Взуттєва Мрія за вигідними цінами ⭐ Найкращі пропозиції ✓ Повернення протягом 14 днів ⏩ Доставка по Україні ✔️ Оплата при отриманні ➤ В нас вигідніше!" />
                <meta name="keywords" content="магазин, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі, знижки, скидки, жіноче взуття, женская обувь" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні" />
                <meta property="og:description" content="Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні." />
                <link rel="icon" href="/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="preload" href={header_img} as="image" />
            </Helmet>

            <Nav />

            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">Взуттєва мрія</h1>
                            <p className="header__text">Життя краще в комфортному взутті</p>
                            <Link className="header__shop btn-primary" to="/shop">ЗА ПОКУПКАМИ</Link>
                        </div>
                        <div className="header__image-container">
                            {/*Використовуємо picture щоб дати webp якщо браузер підтримує */}
                            {webpSupported !== null && (
                                <picture>
                                    {webpSupported && (
                                        <source srcSet={header_img.replace('.webp', '.webp')} type="image/webp" />
                                    )}
                                    <img
                                        ref={headerImageRef}
                                        src={header_img}
                                        alt="Комфортне взуття Взуттєва мрія"
                                        className="header__img"
                                        loading="eager"
                                        fetchpriority="high"
                                        decoding="async"
                                        width="500"  // Явні width та height
                                        height="auto" // Явні width та height
                                    />
                                </picture>
                            )}
                        </div>
                    </div>
                </div>
            </header>

            <section className="popular">
                <div className="container">
                    <h2 className="popular__title section-title">Популярні товари</h2>

                    {popular.length === 0 ? (
                        <div className="loading-container">
                            <p>Завантаження популярних товарів...</p>
                        </div>
                    ) : (
                        <>
                            <div className="popular__products products-grid">
                                {sortedPopular.map((product, index) => (
                                    <Product
                                        key={product.id || index}
                                        edit={false}
                                        data={{
                                            ...product,
                                            // Приклад responsive images, потрібно згенерувати product.imageSmall, product.imageMedium, product.imageLarge
                                            imageSizes: {
                                                small: product.imageSmall,
                                                medium: product.imageMedium,
                                                large: product.imageLarge,
                                            }
                                        }}
                                    />
                                ))}
                            </div>

                            <div className="popular__view-all">
                                <Link to="/shop" className="btn-view-all">
                                    Переглянути повний каталог
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </section>

            <section className="sale">
                <div className="container">
                    <h2 className="sale__title section-title">Розпродаж</h2>

                    {sale.length === 0 ? (
                        <div className="no-sale-container">
                            <p>На даний момент немає товарів зі знижкою.</p>
                        </div>
                    ) : (
                        <div className="sale__wrapper">
                            <ProductSlider data={sale} />
                        </div>
                    )}
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Home;