import { useMemo } from 'react';

const useStructuredData = (data, type) => {
    const structuredData = useMemo(() => {
        if (!data) {
            return null;
        }

        const generateProductSchema = (product) => {
            const cleanImage = product.images.map(image => {
                let cleanImage = image.replace(/\\/g, '/'); // Замінюємо всі зворотні слеші на прямі
                cleanImage = cleanImage.replace('/uploads', 'uploads'); // Забираємо лишній слеш перед uploads
                // Гарантуємо, що між доменом та шляхом до зображення є ОДИН слеш
                let imageUrl = `https://api.shoedream.com.ua/${cleanImage}`;
                imageUrl = imageUrl.replace(/([^:]\/)\/+/g, "$1"); // Видаляємо подвійні слеші
                return imageUrl;
            });
            return {
                "@context": "https://schema.org",
                "@type": "Product",
                "name": product.title,
                "image": cleanImage,
                "description": product.description.replace(/<\/?[^>]+(>|$)/g, ""),
                "brand": {
                    "@type": "Brand",
                    "name": product.brand
                },
                "sku": product.article,
                "material": product.material_top,
                "color": product.color,
                "priceCurrency": "UAH",
                "price": product.discount ? product.new_price : product.price,
                "offers": {
                    "@type": "Offer",
                    "url": `https://www.shoedream.com.ua/shop/${product.slug}`,
                    "priceCurrency": "UAH",
                    "price": product.discount ? product.new_price : product.price,
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": "https://schema.org/InStock"
                },
                "additionalType": "https://schema.org/Shoe",
                "size": product.sizes,
                "season": product.season,
                "countryOfOrigin": product.country,
                "discount": product.discount
            };
        };

        if (Array.isArray(data)) {
            // Обробка списку товарів (для сторінки категорії)
            return {
                "@context": "https://schema.org",
                "@type": "ItemList",
                "itemListElement": data.map((product, index) => ({
                    "@type": "ListItem",
                    "position": index + 1,
                    "item": generateProductSchema(product)
                }))
            };
        } else if (typeof data === 'object' && data !== null) {
            // Обробка окремого товару (для сторінки товару)
            return generateProductSchema(data);
        }

        return null;
    }, [data]);

    return structuredData;
};

export default useStructuredData;