import React, { useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.scss'; // Переконайтеся, що шлях до вашого файлу стилів правильний
import { Product } from '../Product'; // Переконайтеся, що шлях до компонента Product правильний
import { useLocation } from 'react-router-dom';

export const Pagination = ({ items, itemsPerPage = 25, renderImage }) => {
    const paginationRef = useRef(null);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const location = useLocation();

    useEffect(() => {
        // Збереження позиції прокрутки
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        // Спроба відновити сторінку із sessionStorage
        const savedPage = sessionStorage.getItem(`${location.pathname}-page`);
        if (savedPage) {
            const parsedPage = parseInt(savedPage, 10);
            setCurrentPage(parsedPage);
            const newOffset = (parsedPage * itemsPerPage) % items.length;
            setItemOffset(newOffset);
        } else {
            // Якщо сторінку не знайдено в sessionStorage, починаємо з першої сторінки
            setItemOffset(0);
            setCurrentPage(0);
        }

        // Відновлення поведінки прокрутки після розмонтування
        return () => {
            if ('scrollRestoration' in window.history) {
                window.history.scrollRestoration = 'auto';
            }
        };
    }, [location.pathname, itemsPerPage, items]);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
        sessionStorage.setItem(`${location.pathname}-page`, event.selected);
        // Плавно прокручуємо до контейнера пагінації
        paginationRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="pagination-container">
            {/*  Відображення товарів у вигляді сітки */}
            <div className="products-grid" ref={paginationRef}>
                {currentItems.map((item) => (
                    <Product key={item.id} data={item} renderImage={renderImage} />
                ))}
            </div>

            {/* Компонент ReactPaginate */}
            <ReactPaginate
                breakLabel="..."
                nextLabel="→"
                previousLabel="←"
                onPageChange={handlePageClick}
                pageRangeDisplayed={7}
                marginPagesDisplayed={0}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="pagination__page"
                previousLinkClassName={`pagination__prev ${currentPage === 0 ? 'pagination__prev--hidden' : ''}`}
                nextLinkClassName={`pagination__next ${currentPage === pageCount - 1 ? 'pagination__next--hidden' : ''}`}
                breakLinkClassName="pagination__break"
                activeClassName="pagination__page--active"
                disabledClassName="pagination__page--disabled"
                forcePage={currentPage}
            />
        </div>
    );
};