import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import header_img from '../../image/header-logo.webp';
import useStructuredData from '../../hooks/useStructuredData';
import debounce from 'lodash/debounce';

import './choboti.scss'; // Ensure correct path to your shop styles or create choboti.scss

// Компонент EmptyState
const EmptyState = ({ message, actionText, onAction }) => {
    return (
        <div className="empty-state">
            <h3>{message}</h3>
            {actionText && onAction && (
                <button onClick={onAction} className="empty-state__button" aria-label={actionText}>
                    {actionText}
                </button>
            )}
        </div>
    );
};

// Компонент для відображення інформації про кількість товарів
const ProductCount = ({ count, totalCount }) => {
    return (
        <div className="product-count">
            Знайдено товарів: <strong>{count}</strong> {count !== totalCount && `(з ${totalCount})`}
        </div>
    );
};

// Компонент для відображення активних фільтрів
const ActiveFilters = ({ filters, filterOptions, onReset, onRemoveSingleFilter, seasonMap }) => {
    const { selectSize, selectSeason, selectColor } = filters;

    // Перевіряємо, чи є активні фільтри
    const hasActiveFilters = selectSize !== "default" ||
        selectSeason !== "default" ||
        selectColor !== "default";

    if (!hasActiveFilters) return null;

    // Функція для отримання назви сезону
    const getSeasonName = (season) => {
        return seasonMap[season] || season;
    };

    return (
        <div className="active-filters">
            <div className="active-filters__title">Активні фільтри:</div>
            <div className="active-filters__tags">
                {selectSize !== "default" && (
                    <div className="active-filters__tag">
                        Розмір: {selectSize}
                        <button onClick={() => onRemoveSingleFilter('selectSize')} aria-label="Видалити фільтр розміру">×</button>
                    </div>
                )}
                {selectSeason !== "default" && (
                    <div className="active-filters__tag">
                        Сезон: {getSeasonName(selectSeason)}
                        <button onClick={() => onRemoveSingleFilter('selectSeason')} aria-label="Видалити фільтр сезону">×</button>
                    </div>
                )}
                {selectColor !== "default" && (
                    <div className="active-filters__tag">
                        Колір: {selectColor}
                        <button onClick={() => onRemoveSingleFilter('selectColor')} aria-label="Видалити фільтр кольору">×</button>
                    </div>
                )}
                <button className="active-filters__clear" onClick={onReset} aria-label="Скинути всі фільтри">
                    Скинути все
                </button>
            </div>
        </div>
    );
};

// Компонент фільтрів
const FilterPanel = ({
    filterOptions,
    filterValues,
    onFilterChange,
    onReset,
    searchValue,
    onSearchChange,
    onSearchSubmit,
    seasonMap
}) => {
    const { sizes, colors, seasons } = filterOptions;
    const { selectSort, selectSize, selectSeason, selectColor } = filterValues;
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

    return (
        <div className="filter">
            <div className="filter__container">
                <button className="filter__mobile-toggle" onClick={toggleFilter} aria-label="Відкрити/Закрити фільтри">
                    <span>Фільтри</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                    </svg>
                </button>

                <div className={`filter__wrapper ${isFilterOpen ? 'filter__wrapper--open' : ''}`}>
                    <div className="filter__top">
                        <select
                            name="sort"
                            className="filter__select"
                            onChange={(e) => onFilterChange('selectSort', e.target.value)}
                            value={selectSort}
                            aria-label="Сортувати товари"
                        >
                            <option value="default" disabled hidden>Сортувати за</option>
                            <option value="popular">Спочатку популярні</option>
                            <option value="unpopular">Спочатку не популярні</option>
                            <option value="rich">Спочатку дорогі</option>
                            <option value="poor">Спочатку дешеві</option>
                        </select>

                        {sizes.length > 0 && (
                            <select
                                name="size"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectSize', e.target.value)}
                                value={selectSize}
                                aria-label="Фільтрувати за розміром"
                            >
                                <option value="default" disabled hidden>Розмір</option>
                                {sizes.map((size, index) => (
                                    <option key={index} value={size}>{size}</option>
                                ))}
                            </select>
                        )}

                        {seasons.length > 0 && (
                            <select
                                name="season"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectSeason', e.target.value)}
                                value={selectSeason}
                                aria-label="Фільтрувати за сезоном"
                            >
                                <option value="default" disabled hidden>Сезон</option>
                                {seasons.map((season, index) => (
                                    <option key={index} value={season}>{seasonMap[season]}</option>
                                ))}
                            </select>
                        )}

                        {colors.length > 0 && (
                            <select
                                name="color"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectColor', e.target.value)}
                                value={selectColor}
                                aria-label="Фільтрувати за кольором"
                            >
                                <option value="default" disabled hidden>Колір</option>
                                {colors.map((color, index) => (
                                    <option key={index} value={color}>{color}</option>
                                ))}
                            </select>
                        )}
                    </div>

                    <div className="filter__bottom">
                        <button
                            className="filter__reset"
                            onClick={onReset}
                            aria-label="Скинути всі фільтри"
                        >
                            Скинути
                        </button>
                    </div>
                </div>

                <div className="filter__search">
                    <input
                        type="text"
                        className="filter__search-input"
                        placeholder="Пошук товарів"
                        value={searchValue}
                        onChange={onSearchChange}
                        aria-label="Пошук товарів за назвою"
                    />
                    <button
                        className="filter__search-btn"
                        onClick={onSearchSubmit}
                        aria-label="Пошук товарів"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="11" cy="11" r="8" />
                            <line x1="21" y1="21" x2="16.65" y2="16.65" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

const Choboti = () => {
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const { posts, status } = useSelector(state => state.post);
    const type = "Чоботи";
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        selectSort: "default",
        selectSize: "default",
        selectSeason: "default",
        selectColor: "default"
    });
    const [filterOptions, setFilterOptions] = useState({
        sizes: [],
        colors: [],
        seasons: []
    });

    const seasonMap = useMemo(() => ({
        "summer": "Літо",
        "winter": "Зима",
        "spring": "Весна-осінь"
    }), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const applyFilter = useCallback(() => {
        if (!posts.data) return;

        let data = posts.data.filter(elem => elem.type === type);

        if (filters.selectSize !== "default" && filterOptions.sizes.length > 0) {
            data = data.filter(elem => elem.sizes.map(Number).includes(parseInt(filters.selectSize)));
        }
        if (filters.selectSeason !== "default" && filterOptions.seasons.length > 0) {
            data = data.filter(elem => elem.season === filters.selectSeason);
        }
        if (filters.selectColor !== "default" && filterOptions.colors.length > 0) {
            data = data.filter(elem => elem.color === filters.selectColor);
        }

        if (filters.selectSort !== "default") {
            switch (filters.selectSort) {
                case 'popular':
                    data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
                    break;
                case 'unpopular':
                    data = [...data].sort((elem, nextelem) => elem.popular - nextelem.popular);
                    break;
                case 'rich':
                    data = [...data].sort((elem, nextelem) => nextelem.price - nextelem.price);
                    break;
                case 'poor':
                    data = [...data].sort((elem, nextelem) => elem.price - nextelem.price);
                    break;
                default:
                    break;
            }
        }

        if (search) {
            data = [...data].filter(elem =>
                elem.title.toLowerCase().includes(search.toLowerCase())
            );
        }
        setFilterData(data);
    }, [posts.data, type, filters, filterOptions, search]);

    const searchProduct = useCallback((value) => {
        if (!posts.data) return;

        let data = posts.data.filter(item => item.type === type);
        if (value) {
            data = [...data].filter(elem =>
                elem.title.toLowerCase().includes(value.toLowerCase())
            );
        }
        setFilterData(data);
    }, [posts.data, type]);

    useEffect(() => {
        if (posts.data) {
            // Get available seasons
            const availableSeasonsSet = new Set(
                posts.data
                    .filter(item => item.type === type)
                    .map(item => item.season)
            );
            const availableSeasons = Array.from(availableSeasonsSet);

            const sizes = [];
            const colors = [];

            posts.data.filter(item => item.type === type).forEach(post => {
                post.sizes.forEach(size => {
                    if (!sizes.includes(size)) {
                        sizes.push(size);
                    }
                });
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
            });

            sizes.sort((a, b) => Number(a) - Number(b));
            colors.sort();

            setFilterOptions({
                sizes: sizes,
                colors: colors,
                seasons: availableSeasons
            });

            setLoading(false);
        }
    }, [posts.data, type]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: value
        }));
    };

    const handleResetFilters = () => {
        setFilters({
            selectSort: "default",
            selectSize: "default",
            selectSeason: "default",
            selectColor: "default"
        });
        setSearch("");
    };

    const handleRemoveSingleFilter = (filterName) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: "default"
        }));
    };

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        applyFilter();
    };

    const debouncedSearch = useMemo(
        () => debounce(handleSearchInputChange, 300),
        []
    );

     const structuredData = useStructuredData(filterData || (status === 'success' && posts.data ? posts.data.filter(item => item.type === type) : null));

     const totalProductsCount = useMemo(() => {
        return posts.data ? posts.data.filter(item => item.type === type).length : 0;
    }, [posts.data, type]);


    const metaTitle = useMemo(() => "Жіночі Чоботи: Зимові, Шкіряні, Труби, на Підборах | Взуттєва Мрія", []);
    const metaDescription = useMemo(() => "Купуйте жіночі чоботи в інтернет-магазині 'Взуттєва Мрія'! Великий вибір зимових, демісезонних, шкіряних та замшевих чобіт-труб, високих чобіт, на підборах та без каблука.  Акції, знижки, вигідні ціни та доставка по Україні.", []);
    const metaKeywords = useMemo(() => "жіночі чоботи, купити чоботи жіночі, чоботи зимові жіночі, чоботи шкіряні жіночі, чоботи замшеві жіночі, чоботи труби, чоботи труби зимові, чоботи високі жіночі, чоботи на підборах, чоботи на платформі, чоботи челсі жіночі, чоботи великих розмірів, чоботи чорні жіночі, чоботи бежеві жіночі, чоботи коричневі жіночі, чоботи недорого, чоботи розпродаж, інтернет-магазин взуття", []);
    const headerTitle = useMemo(() => "Жіночі Чоботи", []);
    const headerText = useMemo(() => "Ознайомтеся з колекцією жіночих чобіт 2025 року від 'Взуттєвої Мрії'!  Трендові чоботи-труби, високі чоботи, зимові та осінні моделі з натуральної шкіри, замші та екошкіри.  Знайдіть свою ідеальну пару за вигідною ціною!", []);


    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">{headerTitle}</h1>
                            <p className="header__text">{headerText}</p>
                        </div>
                        <img src={header_img} alt="Модні жіночі чоботи колекції 2025 року" className="header__img" loading="lazy" />
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                      {/* Компонент фільтрів */}
                    <FilterPanel
                        filterOptions={filterOptions}
                        filterValues={filters}
                        onFilterChange={handleFilterChange}
                        onReset={handleResetFilters}
                        searchValue={search}
                        onSearchChange={debouncedSearch}
                        onSearchSubmit={handleSearchButtonClick}
                        seasonMap={seasonMap}
                    />

                    {/* Компонент активних фільтрів */}
                    <ActiveFilters
                        filters={filters}
                        filterOptions={filterOptions}
                        onReset={handleResetFilters}
                        onRemoveSingleFilter={handleRemoveSingleFilter}
                        seasonMap={seasonMap}
                    />

                    {/* Відображення кількості товарів */}
                    {status !== 'loading' && totalProductsCount > 0 && (
                        <ProductCount count={filterData.length} totalCount={totalProductsCount} />
                    )}

                    {/* Стан завантаження */}
                    {status === 'loading' && <Spiner />}

                    {/* Відображення товарів або порожнього стану */}
                    {status !== 'loading' && totalProductsCount > 0 && (
                        <>
                            {filterData.length > 0 ? (
                                <div className="goods__all" ref={el => { }}>
                                    <Pagination items={filterData || (posts.data ? posts.data.filter(item => item.type === type) : [])}
                                        renderImage={(src) => (
                                            <img src={src} alt="Жіночі Чоботи Взуттєва Мрія" loading="lazy" />
                                        )}
                                    />
                                </div>
                            ) : (
                                <EmptyState
                                    message="Товари не знайдено за вашим запитом"
                                    actionText="Скинути фільтри"
                                    onAction={handleResetFilters}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>

             <div className="seo-text-block">
                <div className="container">
                    <div className="row">
                         <h2 style={{ textAlign: 'center' }}>Купити жіночі чоботи недорого: зимові, шкіряні, труби - великий вибір в "Взуттєвій Мрії"</h2>
                        <p style={{ textAlign: 'justify' }}>Кожна жінка мріє про ідеальні <strong>жіночі чоботи</strong>, що поєднують красу, комфорт та тепло.  Інтернет-магазин "Взуттєва Мрія" втілює ці мрії в реальність, пропонуючи широкий вибір <strong>жіночих чобіт в Україні</strong> за вигідними цінами.  У нашому каталозі ви знайдете трендові моделі на будь-який смак та сезон: від класичних <strong>шкіряних чобіт жіночих</strong> та елегантних <strong>замшевих чобіт жіночих</strong> до ультрамодних <strong>чобіт-труб жіночих</strong> та теплих <strong>зимових чобіт жіночих</strong>.  З нами ви завжди будете на висоті!</p>

                        <h2 style={{ textAlign: 'center' }}>Різноманітність жіночих чобіт: від класики до трендів 2025</h2>
                        <p style={{ textAlign: 'justify' }}>Ознайомтеся з нашим багатим асортиментом <strong>жіночих чобіт</strong>, що включає:</p>
                        <ul>
                            <li> <strong><em>Трендові чоботи-труби:</em></strong> <em>Хіт сезону 2025! <strong>Чоботи жіночі труби</strong> – це сміливий вибір для модниць, що цінують стиль та оригінальність.  Особливо популярні <strong>зимові чоботи труби жіночі</strong>, що поєднують трендовий дизайн та тепло.</em></li>
                            <li> <strong><em>Класичні шкіряні та замшеві чоботи:</em></strong> <em>Елегантна класика на всі часи. <strong>Шкіряні чоботи жіночі купити</strong> – це вибір на користь практичності та вишуканості. <strong>Замшеві чоботи жіночі купити</strong> – додати образу розкоші та м'якості.</em></li>
                            <li> <strong><em>Сезонні колекції:</em></strong> <em>У нас ви знайдете <strong>жіночі зимові чоботи</strong> для найтепліших та комфортних образів у холодну погоду, <strong>осінні чоботи жіночі</strong> для міжсезоння, а також <strong>осінні чоботи жіночі</strong> та <strong>весняні чоботи жіночі</strong> для будь-якої погоди.</em></li>
                             <li> <strong><em>Різні стилі та висота каблука:</em></strong> <em>Обирайте <strong>чоботи на підборах жіночі</strong> для створення витончених та жіночних силуетів, <strong>чоботи на платформі жіночі</strong> для стійкості та комфорту, <strong>чоботи на танкетці жіночі</strong> або <strong>чоботи без каблука жіночі</strong> для повсякденного носіння.  Для тих, хто цінує зручність та практичність, пропонуємо <strong>чоботи на низькому ходу жіночі</strong>.</em></li>
                            <li> <strong><em>Широка кольорова гама:</em></strong> <em>У нас ви можете <strong>купити чорні чоботи жіночі</strong> – універсальну класику на всі часи, <strong>бежеві чоботи жіночі</strong> – для створення ніжних та елегантних образів, <strong>коричневі чоботи жіночі</strong> – для теплих та затишних луків, а також <strong>білі чоботи жіночі</strong> та <strong>сірі чоботи жіночі</strong> для створення оригінальних та стильних акцентів.</em></li>
                            <li> <strong><em>Чоботи для будь-якої фігури:</em></strong> <em>У нашому каталозі представлені <strong>чоботи жіночі великих розмірів</strong> та моделі <strong>чобіт на широку литку</strong>, щоб кожна жінка могла знайти ідеальну пару, що підкреслить красу її ніг.</em></li>
                        </ul>

                        <h3 style={{ textAlign: 'center' }}>Вигідні ціни на жіночі чоботи та постійні знижки</h3>
                        <p style={{ textAlign: 'justify' }}>Не пропустіть вигідну можливість <strong>купити жіночі чоботи зі знижкою</strong> в інтернет-магазині "Взуттєва Мрія"!  Слідкуйте за нашими <strong>розпродажами жіночих чобіт</strong> та акціями, щоб придбати взуття своєї мрії за найпривабливішою ціною.  Ми робимо стиль доступним кожній жінці!</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Choboti;