import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import header_img from '../../image/header-logo.webp';
import useStructuredData from '../../hooks/useStructuredData';

const Mokasini = () => {
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const { posts, status } = useSelector(state => state.post);
    const type = "Мокасини";
    const [loading, setLoading] = useState(true);
    const [selectSort, setSelectSort] = useState("default");
    const [selectSize, setSelectSize] = useState("default");
    const [selectSeason, setSelectSeason] = useState("default");
    const [selectColor, setSelectColor] = useState("default");
    const [size, setSize] = useState([]);
    const [colors, setColors] = useState([]);
    const [seasons, setSeasons] = useState([]); // Стан для зберігання доступних сезонів
    const [hasSizes, setHasSizes] = useState(false);
    const [hasColors, setHasColors] = useState(false);
    const [hasSeasons, setHasSeasons] = useState(false);

     // Об'єкт зіставлення англійських назв сезонів з українськими
    const seasonMap = useMemo(() => ({
        "summer": "Літо",
        "winter": "Зима",
        "spring": "Весна-осінь"
    }), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const applyFilter = useCallback(() => {
        if (!posts.data) return;

        let data = posts.data.filter(item => item.type === type);

        if (selectSize !== "default" && hasSizes) {
            data = data.filter(elem => elem.sizes.map(Number).includes(parseInt(selectSize)));
        }
        if (selectSeason !== "default" && hasSeasons) {
            data = data.filter(elem => elem.season === selectSeason);
        }
        if (selectColor !== "default" && hasColors) {
            data = data.filter(elem => elem.color === selectColor);
        }

        if (selectSort !== "default") {
            switch (selectSort) {
                case 'popular':
                    data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
                    break;
                case 'unpopular':
                    data = [...data].sort((elem, nextelem) => elem.popular - elem.popular);
                    break;
                case 'rich':
                    data = [...data].sort((elem, nextelem) => nextelem.price - elem.price);
                    break;
                case 'poor':
                    data = [...data].sort((elem, nextelem) => elem.price - elem.price);
                    break;
                default:
                    break;
            }
        }
        setFilterData(data);
    }, [posts.data, type, selectSize, hasSizes, selectSeason, hasSeasons, selectColor, hasColors, selectSort]);

    const searchProduct = useCallback((value) => {
        if (!posts.data) return;

        let data = posts.data.filter(item => item.type === type);
        if (value) {
            data = [...data].filter(elem =>
                elem.title.toLowerCase().includes(value.toLowerCase())
            );
        }
        setFilterData(data);
    }, [posts.data, type]);

    // useEffect для отримання унікальних значень для фільтрів (розміри, кольори, сезони)
    useEffect(() => {
        if (posts.data) {
            const filteredData = posts.data.filter(item => item.type === type);

            const sizes = [];
            const colors = [];
            // Збираємо сезони лише з відфільтрованих даних
            const availableSeasonsSet = new Set(filteredData.map(item => item.season));
            const availableSeasons = Array.from(availableSeasonsSet);

            filteredData.forEach(post => {
                post.sizes.forEach(size => {
                    if (!sizes.includes(size)) {
                        sizes.push(size);
                    }
                });
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
            });

            setHasSizes(sizes.length > 0);
            setHasColors(colors.length > 0);
            setHasSeasons(availableSeasons.length > 0);
            // Встановлюємо стани
            setSeasons(availableSeasons);
            setSize(sizes);
            setColors(colors);
            setLoading(false);
        }
    }, [posts.data, type]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter, selectSort, selectSize, selectSeason, selectColor]);

    useEffect(() => {
        searchProduct(search);
    }, [search, searchProduct]);

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        searchProduct(search);
    };

    const deleteFilter = () => {
        setSelectSort("default");
        setSelectSize("default");
        setSelectSeason("default");
        setSelectColor("default");
        setSearch("");
    };

    const structuredData = useStructuredData(filterData || (status === 'success' && posts.data ? posts.data.filter(item => item.type === type) : null));

    // Генеруємо унікальний опис для кожної комбінації фільтрів
    const metaTitle = useMemo(() => "Мокасини жіночі: великий вибір, вигідні ціни | Взуттєва Мрія", []);
    const metaDescription = useMemo(() => {
        let description = "Великий вибір жіночих мокасинів. Шкіряні та замшеві моделі. Зручна доставка по Україні. Акції та знижки. Оновлення колекцій щосезону.";

        if (selectSize !== "default") {
            description += ` розміру ${selectSize}`;
        }
        if (selectSeason !== "default") {
            description += ` для сезону ${seasonMap[selectSeason] || selectSeason}`;
        }
        if (selectColor !== "default") {
            description += ` в кольорі ${selectColor}`;
        }
        return description;
    }, [selectSize, selectSeason, selectColor, seasonMap]);
    const metaKeywords = useMemo(() => "мокасини, купити мокасини, жіночі мокасини, взуття жіноче, інтернет-магазин взуття, Україна", []);
    const headerTitle = useMemo(() => "Купити Мокасини Жіночі", []);
    const headerText = useMemo(() => "Оновіть свій гардероб стильною парою мокасинів в нашому інтернет-магазині. Великий вибір, зручна доставка по Україні!", []);

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">{headerTitle}</h1>
                            <p className="header__text">{headerText}</p>
                        </div>
                        <img src={header_img} alt="Жіночі Мокасини" className="header__img" loading="lazy" />
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                    <div className="filter">
                        <div className="filter__top">
                            <select name="sort" className="filter__sort" onChange={(event) => setSelectSort(event.target.value)} value={selectSort}>
                                <option value="default" disable="false" hidden>Сортувати за</option>
                                <option value="popular">Спочатку популярні</option>
                                <option value="unpopular">Спочатку не популярні</option>
                                <option value="rich">Спочатку дорогі</option>
                                <option value="poor">Спочатку дешеві</option>
                            </select>
                            {hasSizes && <select name="size" className="filter__size" onChange={(event) => setSelectSize(event.target.value)} value={selectSize}>
                                <option value="default" disable="false" hidden>Розмір</option>
                                {
                                    size.map((elem, index) => (
                                        <option key={index} value={elem}>{elem}</option>
                                    ))
                                }
                            </select>}
                            {hasSeasons && (
                                <select
                                    name="season"
                                    className="filter__season"
                                    onChange={(event) => setSelectSeason(event.target.value)}
                                    value={selectSeason}
                                >
                                    <option value="default" disable="false" hidden>Сезон</option>
                                    {seasons.map((season, index) => (
                                        <option key={index} value={season}>
                                            {seasonMap[season] || season}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {hasColors && <select name="color" className="filter__color" onChange={(event) => setSelectColor(event.target.value)} value={selectColor}>
                                <option value="default" disable="false" hidden>Колір</option>
                                {
                                    colors.map((elem, index) => (
                                        <option key={index} value={elem}>{elem}</option>
                                    ))
                                }
                            </select>}
            </div>
            <div className="filter__bottom">
              <button className="filter__delete" onClick={deleteFilter}>Скинути</button>
              <div className="filter__search">
                <button className="filter__search-btn" onClick={handleSearchButtonClick}></button>
                <input type="text" className="filter__search-input" placeholder='Пошук' value={search} onChange={handleSearchInputChange} />
              </div>
            </div>
          </div>
          {loading ? <Spiner /> :
            <Pagination items={filterData}
              renderImage={(src) => (
                <img src={src} alt={`Жіночі мокасини ${src}`} loading="lazy" />
              )}
            />
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Mokasini;