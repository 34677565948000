import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import header_img from '../../image/header-logo.webp';
import useStructuredData from '../../hooks/useStructuredData';
import debounce from 'lodash/debounce';

import '../Shop/shop.scss'; // Ensure correct path to your shop styles or create botforti.scss

// Компонент EmptyState
const EmptyState = ({ message, actionText, onAction }) => {
    return (
        <div className="empty-state">
            <h3>{message}</h3>
            {actionText && onAction && (
                <button onClick={onAction} className="empty-state__button" aria-label={actionText}>
                    {actionText}
                </button>
            )}
        </div>
    );
};

// Компонент для відображення інформації про кількість товарів
const ProductCount = ({ count, totalCount }) => {
    return (
        <div className="product-count">
            Знайдено товарів: <strong>{count}</strong> {count !== totalCount && `(з ${totalCount})`}
        </div>
    );
};

// Компонент для відображення активних фільтрів
const ActiveFilters = ({ filters, filterOptions, onReset, onRemoveSingleFilter, seasonMap }) => {
    const { selectSize, selectSeason, selectColor } = filters;

    // Перевіряємо, чи є активні фільтри
    const hasActiveFilters = selectSize !== "default" ||
        selectSeason !== "default" ||
        selectColor !== "default";

    if (!hasActiveFilters) return null;

    // Функція для отримання назви сезону
    const getSeasonName = (season) => {
        return seasonMap[season] || season;
    };

    return (
        <div className="active-filters">
            <div className="active-filters__title">Активні фільтри:</div>
            <div className="active-filters__tags">
                {selectSize !== "default" && (
                    <div className="active-filters__tag">
                        Розмір: {selectSize}
                        <button onClick={() => onRemoveSingleFilter('selectSize')} aria-label="Видалити фільтр розміру">×</button>
                    </div>
                )}
                {selectSeason !== "default" && (
                    <div className="active-filters__tag">
                        Сезон: {getSeasonName(selectSeason)}
                        <button onClick={() => onRemoveSingleFilter('selectSeason')} aria-label="Видалити фільтр сезону">×</button>
                    </div>
                )}
                {selectColor !== "default" && (
                    <div className="active-filters__tag">
                        Колір: {selectColor}
                        <button onClick={() => onRemoveSingleFilter('selectColor')} aria-label="Видалити фільтр кольору">×</button>
                    </div>
                )}
                <button className="active-filters__clear" onClick={onReset} aria-label="Скинути всі фільтри">
                    Скинути все
                </button>
            </div>
        </div>
    );
};

// Компонент фільтрів
const FilterPanel = ({
    filterOptions,
    filterValues,
    onFilterChange,
    onReset,
    searchValue,
    onSearchChange,
    onSearchSubmit,
    seasonMap
}) => {
    const { sizes, colors, seasons } = filterOptions;
    const { selectSort, selectSize, selectSeason, selectColor } = filterValues;
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

    return (
        <div className="filter">
            <div className="filter__container">
                <button className="filter__mobile-toggle" onClick={toggleFilter} aria-label="Відкрити/Закрити фільтри">
                    <span>Фільтри</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                    </svg>
                </button>

                <div className={`filter__wrapper ${isFilterOpen ? 'filter__wrapper--open' : ''}`}>
                    <div className="filter__top">
                        <select
                            name="sort"
                            className="filter__select"
                            onChange={(e) => onFilterChange('selectSort', e.target.value)}
                            value={selectSort}
                            aria-label="Сортувати товари"
                        >
                            <option value="default" disabled hidden>Сортувати за</option>
                            <option value="popular">Спочатку популярні</option>
                            <option value="unpopular">Спочатку не популярні</option>
                            <option value="rich">Спочатку дорогі</option>
                            <option value="poor">Спочатку дешеві</option>
                        </select>

                        {sizes.length > 0 && (
                            <select
                                name="size"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectSize', e.target.value)}
                                value={selectSize}
                                aria-label="Фільтрувати за розміром"
                            >
                                <option value="default" disabled hidden>Розмір</option>
                                {sizes.map((size, index) => (
                                    <option key={index} value={size}>{size}</option>
                                ))}
                            </select>
                        )}

                        {seasons.length > 0 && (
                            <select
                                name="season"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectSeason', e.target.value)}
                                value={selectSeason}
                                aria-label="Фільтрувати за сезоном"
                            >
                                <option value="default" disabled hidden>Сезон</option>
                                {seasons.map((season, index) => (
                                    <option key={index} value={season}>{seasonMap[season]}</option>
                                ))}
                            </select>
                        )}

                        {colors.length > 0 && (
                            <select
                                name="color"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectColor', e.target.value)}
                                value={selectColor}
                                aria-label="Фільтрувати за кольором"
                            >
                                <option value="default" disabled hidden>Колір</option>
                                {colors.map((color, index) => (
                                    <option key={index} value={color}>{color}</option>
                                ))}
                            </select>
                        )}
                    </div>

                    <div className="filter__bottom">
                        <button
                            className="filter__reset"
                            onClick={onReset}
                            aria-label="Скинути всі фільтри"
                        >
                            Скинути
                        </button>
                    </div>
                </div>

                <div className="filter__search">
                    <input
                        type="text"
                        className="filter__search-input"
                        placeholder="Пошук товарів"
                        value={searchValue}
                        onChange={onSearchChange}
                        aria-label="Пошук товарів за назвою"
                    />
                    <button
                        className="filter__search-btn"
                        onClick={onSearchSubmit}
                        aria-label="Пошук товарів"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="11" cy="11" r="8" />
                            <line x1="21" y1="21" x2="16.65" y2="16.65" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

const Botforti = () => {
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const { posts, status } = useSelector(state => state.post);
    const type = "Ботфорти";
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        selectSort: "default",
        selectSize: "default",
        selectSeason: "default",
        selectColor: "default"
    });
    const [filterOptions, setFilterOptions] = useState({
        sizes: [],
        colors: [],
        seasons: []
    });

    const seasonMap = useMemo(() => ({
        "summer": "Літні",
        "winter": "Зимові",
        "spring": "Демісезонні"
    }), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const applyFilter = useCallback(() => {
        if (!posts.data) return;

        let data = posts.data.filter(elem => elem.type === type);

        if (filters.selectSize !== "default" && filterOptions.sizes.length > 0) {
            data = data.filter(elem => elem.sizes.map(Number).includes(parseInt(filters.selectSize)));
        }
        if (filters.selectSeason !== "default" && filterOptions.seasons.length > 0) {
            data = data.filter(elem => elem.season === filters.selectSeason);
        }
        if (filters.selectColor !== "default" && filterOptions.colors.length > 0) {
            data = data.filter(elem => elem.color === filters.selectColor);
        }

        if (filters.selectSort !== "default") {
            switch (filters.selectSort) {
                case 'popular':
                    data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
                    break;
                case 'unpopular':
                    data = [...data].sort((elem, nextelem) => elem.popular - nextelem.popular);
                    break;
                case 'rich':
                    data = [...data].sort((elem, nextelem) => nextelem.price - elem.price);
                    break;
                case 'poor':
                    data = [...data].sort((elem, nextelem) => elem.price - nextelem.price);
                    break;
                default:
                    break;
            }
        }

        if (search) {
            data = [...data].filter(elem =>
                elem.title.toLowerCase().includes(search.toLowerCase())
            );
        }
        setFilterData(data);
    }, [posts.data, type, filters, filterOptions, search]);

    useEffect(() => {
        if (posts.data) {
            const filteredData = posts.data.filter(item => item.type === type);

            const sizes = [];
            const colors = [];
            const availableSeasonsSet = new Set(filteredData.map(item => item.season));
            const availableSeasons = Array.from(availableSeasonsSet);

            filteredData.forEach(post => {
                post.sizes.forEach(size => {
                    if (!sizes.includes(size)) {
                        sizes.push(size);
                    }
                });
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
            });

            sizes.sort((a, b) => Number(a) - Number(b));
            colors.sort();

            setFilterOptions({
                sizes: sizes,
                colors: colors,
                seasons: availableSeasons
            });
            setLoading(false);
        }
    }, [posts.data, type]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: value
        }));
    };

    const handleResetFilters = () => {
        setFilters({
            selectSort: "default",
            selectSize: "default",
            selectSeason: "default",
            selectColor: "default"
        });
        setSearch("");
    };

    const handleRemoveSingleFilter = (filterName) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: "default"
        }));
    };

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        applyFilter();
    };

    const debouncedSearch = useMemo(
        () => debounce(handleSearchInputChange, 300),
        []
    );

    const structuredData = useStructuredData(filterData || (status === 'success' && posts.data ? posts.data.filter(item => item.type === type) : null));

    const metaTitle = useMemo(() => "Купити Ботфорти Жіночі: Шкіряні, Замшеві, Зимові, Осінні | Взуттєва Мрія", []);
    const metaDescription = useMemo(() => {
        let description = "Замовте жіночі ботфорти в інтернет-магазині 'Взуттєва Мрія' за вигідною ціною! В наявності шкіряні, замшеві та зимові ботфорти-панчохи, ботфорти на каблуку та без. Великий вибір кольорів: чорні, бежеві, коричневі та ін. Доставка по Україні.";
        if (filters.selectSize !== "default") {
            description += ` Розмір ${filters.selectSize}.`;
        }
        if (filters.selectSeason !== "default" && filters.selectSeason in seasonMap) {
            description += ` Сезон ${seasonMap[filters.selectSeason] || filters.selectSeason}.`;
        }
        if (filters.selectColor !== "default") {
            description += ` Колір ${filters.selectColor}.`;
        }
        if (search) {
            description += ` Пошук: "${search}".`;
        }
        return description
    }, [filters, search, seasonMap]);
    const metaKeywords = useMemo(() => "ботфорти жіночі, купити ботфорти жіночі Україна, ботфорти зимові, ботфорти шкіряні, ботфорти замшеві, ботфорти на каблуку, ботфорти на платформі, ботфорти панчохи, ботфорти чулки, ботфорти на широку литку, ботфорти великих розмірів, ботфорти чорні, ботфорти бежеві, ботфорти коричневі, ботфорти розпродаж, ботфорти знижки, інтернет-магазин взуття", []);
    const headerTitle = useMemo(() => "Купити Жіночі Ботфорти в Україні", []);
    const headerText = useMemo(() => "Широкий вибір жіночих ботфортів з натуральної шкіри, замші та екошкіри для будь-якого стилю та сезону. Зимові, осінні, на каблуку та без каблука, ботфорти-панчохи та інші модні моделі. Вигідні ціни та швидка доставка!", []);

    const totalProductsCount = useMemo(() => {
        return posts.data ? posts.data.filter(item => item.type === type).length : 0;
    }, [posts.data, type]);

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">{headerTitle}</h1>
                            <p className="header__text">{headerText}</p>
                        </div>
                        <img src={header_img} alt="Різноманіття жіночих ботфортів для будь-якого стилю" className="header__img" loading="lazy" />
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                    {/* Компонент фільтрів */}
                    <FilterPanel
                        filterOptions={filterOptions}
                        filterValues={filters}
                        onFilterChange={handleFilterChange}
                        onReset={handleResetFilters}
                        searchValue={search}
                        onSearchChange={debouncedSearch}
                        onSearchSubmit={handleSearchButtonClick}
                        seasonMap={seasonMap}
                    />

                    {/* Компонент активних фільтрів */}
                    <ActiveFilters
                        filters={filters}
                        filterOptions={filterOptions}
                        onReset={handleResetFilters}
                        onRemoveSingleFilter={handleRemoveSingleFilter}
                        seasonMap={seasonMap}
                    />

                    {/* Відображення кількості товарів */}
                    {status !== 'loading' && totalProductsCount > 0 && (
                        <ProductCount count={filterData.length} totalCount={totalProductsCount} />
                    )}

                    {/* Стан завантаження */}
                    {status === 'loading' && <Spiner />}

                    {/* Відображення товарів або порожнього стану */}
                    {status !== 'loading' && totalProductsCount > 0 && (
                        <>
                            {filterData.length > 0 ? (
                                <div className="goods__all" ref={el => { }}>
                                    <Pagination
                                        items={filterData}
                                        renderImage={(src, item) => (
                                            <img
                                                src={src}
                                                alt={`Жіночі ботфорти: ${item.title}, ${item.color}, ${item.material}`}
                                                loading="lazy"
                                            />
                                        )}
                                    />
                                </div>
                            ) : (
                                <EmptyState
                                    message="Товари не знайдено за вашим запитом"
                                    actionText="Скинути фільтри"
                                    onAction={handleResetFilters}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>

             <div className="seo-text-block">
                <div className="container">
                    <div className="row">
                        <h2 style={{ textAlign: 'center' }}>Жіночі ботфорти: широкий вибір моделей для будь-якої фігури</h2>
                        <p style={{ textAlign: 'justify' }}>Високі <strong>жіночі ботфорти</strong> – це тренд, що не втрачає актуальності, ідеальне рішення для жінок, які цінують стиль, комфорт та тепло.  Інтернет-магазин "Взуттєва Мрія" пропонує <strong>купити ботфорти жіночі в Україні</strong>, враховуючи потреби кожної клієнтки. У нашому каталозі ви знайдете <strong>ботфорти на широку литку</strong>, <strong>ботфорти великих розмірів</strong>, а також витончені моделі для струнких ніжок.  Підкресліть свою індивідуальність з нашою колекцією <strong>жіночих ботфортів</strong>!</p>

                        <h2 style={{ textAlign: 'center' }}>Різноманіття матеріалів та стилів жіночих ботфортів</h2>
                        <p style={{ textAlign: 'justify' }}>В "Взуттєвій Мрії" ви знайдете <strong>ботфорти жіночі</strong> на будь-який смак та випадок:</p>
                        <ul>
                            <li> <strong><em>Ботфорти шкіряні та замшеві:</em></strong> <em>Вишукані <strong>ботфорти шкіряні жіночі</strong> – вибір на користь класики та елегантності. <strong>Замшеві ботфорти жіночі</strong> додадуть образу м'якості та розкоші. Обидва матеріали гарантують високу якість та довговічність.</em></li>
                            <li> <strong><em>Ботфорти-панчохи:</em></strong> <em>Трендова модель, що ідеально облягає ногу, створюючи ефект "другої шкіри". <strong>Ботфорти-панчохи жіночі купити</strong> – це означає бути на піку модної хвилі. Особливо популярні <strong>чорні ботфорти-панчохи</strong>, що підходять до будь-якого образу.</em></li>
                            <li> <strong><em>Ботфорти на каблуку, платформі та без каблука:</em></strong> <em>Обирайте висоту каблука в залежності від вашого стилю та потреб. <strong>Ботфорти на каблуку жіночі</strong> додадуть елегантності, <strong>ботфорти на платформі жіночі</strong> – стійкості та трендового вигляду, а <strong>ботфорти без каблука жіночі</strong> забезпечать максимальний комфорт на кожен день. Також зверніть увагу на <strong>ботфорти на низькому ходу</strong> – зручний та практичний варіант.</em></li>
                             <li> <strong><em>Сезонні моделі:</em></strong> <em>Для зимового періоду пропонуємо <strong>ботфорти зимові жіночі з хутром</strong>, що забезпечать тепло та затишок у холодну погоду. Для міжсезоння обирайте <strong>осінні ботфорти жіночі</strong> з водовідштовхувальних матеріалів.</em></li>
                            <li> <strong><em>Кольорова палітра:</em></strong> <em>У нас ви знайдете <strong>ботфорти бежеві жіночі</strong> для створення ніжних та романтичних образів, класичні <strong>чорні ботфорти жіночі</strong>, елегантні <strong>коричневі ботфорти жіночі</strong>, а також яскраві <strong>червоні ботфорти жіночі</strong> для сміливих модниць.</em></li>
                        </ul>

                        <h3 style={{ textAlign: 'center' }}>Вигідні ціни та акції на жіночі ботфорти в "Взуттєвій Мрії"</h3>
                        <p style={{ textAlign: 'justify' }}>Не пропустіть шанс <strong>купити жіночі ботфорти недорого</strong> в інтернет-магазині "Взуттєва Мрія"!  Скористайтеся нашими вигідними пропозиціями, <strong>знижками на ботфорти</strong> та <strong>розпродажем ботфортів</strong>, щоб придбати якісне та стильне взуття за найкращою ціною.  Замовляйте <strong>жіночі ботфорти</strong> онлайн з доставкою по Україні та насолоджуйтесь комфортом та елегантністю щодня!</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Botforti;