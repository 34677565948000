import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../axios';
import InputMask from 'react-input-mask';
import CityAutocomplete from "./CityAutocomplete";

import './basket.scss';
import { Nav, Footer, ProductMin } from "../../components";
import { selectIsAuth } from "../../redux/slices/auth";
import Modal from "../../components/ConfirmationModal";

export const Basket = () => {
    const navigate = useNavigate();
    const isAuth = useSelector(selectIsAuth);
    const { posts } = useSelector(state => state.post);
    const [products, setProducts] = useState(null);
    const [price, setPrice] = useState(0);
    const [deleted, setDeleted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1); // 1 - перегляд кошика, 2 - оформлення замовлення
    
    // Додаємо константу для базового URL зображень
    const API_BASE_URL = 'https://api.shoedream.com.ua';

    // Дані форми
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        city: '',
        department: '',
        comment: ''
    });

    // Стан для помилок валідації
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const data = JSON.parse(window.localStorage.getItem('basket'));
        if (data && data.length > 0) { 
            setProducts(data);
            let count = 0;
            data.forEach(product => {
                // Враховуємо знижену ціну, якщо вона є
                const productPrice = product.new_price || product.price;
                count += parseInt(productPrice) * (product.quantity || 1);
            });
            setPrice(count);
        } else {
            setProducts(null);
            setPrice(0);
        }
    }, [deleted]);

    const deleteProduct = (id) => {
        const basket = JSON.parse(window.localStorage.getItem('basket'));
        const postId = basket.findIndex((elem) => elem.product === id);
        basket.splice(postId, 1);
        window.localStorage.setItem('basket', JSON.stringify(basket));

        if (basket.length === 0) {
            setProducts(null); 
            setPrice(0);
        } else {
            setProducts([...basket]);
            let count = 0;
            basket.forEach(product => {
                // Враховуємо знижену ціну, якщо вона є
                const productPrice = product.new_price || product.price;
                count += parseInt(productPrice) * (product.quantity || 1);
            });
            setPrice(count);
        }

        setDeleted(!deleted);
    }

    // Оновлення кількості товару в localStorage при зміні
    const updateQuantity = (productId, newQuantity) => {
        const basket = JSON.parse(window.localStorage.getItem('basket'));
        const productIndex = basket.findIndex((elem) => elem.product === productId);
        
        if (productIndex !== -1) {
            basket[productIndex].quantity = newQuantity;
            window.localStorage.setItem('basket', JSON.stringify(basket));
            
            // Перерахунок загальної вартості з урахуванням знижок
            let count = 0;
            basket.forEach(product => {
                const productPrice = product.new_price || product.price;
                count += parseInt(productPrice) * (product.quantity || 1);
            });
            setPrice(count);
            setProducts([...basket]);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.firstName.trim()) {
            newErrors.firstName = "Ім'я є обов'язковим";
        }
        
        if (!formData.lastName.trim()) {
            newErrors.lastName = "Прізвище є обов'язковим";
        }
        
        if (!formData.phone.trim() || formData.phone.includes('_')) {
            newErrors.phone = "Введіть коректний номер телефону";
        }
        
        if (!formData.city.trim()) {
            newErrors.city = "Місто є обов'язковим";
        }
        
        if (!formData.department.trim()) {
            newErrors.department = "Відділення є обов'язковим";
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const Order = async () => {
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        
        const order = {
            products: products.map(({ product, size, quantity = 1 }) => {
                const dataProduct = posts.data.find((elem) => elem._id === product);
                
                if (!dataProduct) {
                    console.error(`Product with ID ${product} not found.`);
                    return null;
                }
    
                return {
                    product: dataProduct._id,
                    size,
                    count: quantity,
                    slug: dataProduct.slug,
                    article: dataProduct.article,
                    title: dataProduct.title,
                    price: dataProduct.price,
                    new_price: dataProduct.new_price,
                    images: dataProduct.images
                };
            }).filter(item => item !== null),
            customerData: formData
        };
    
        if (order.products.length === 0) {
            setIsLoading(false);
            alert("Не вдалося створити замовлення: жоден продукт не знайдено.");
            return;
        }
    
        try {
            await axios.post('/order', order);
            setProducts(null);
            window.localStorage.removeItem('basket');
            setFormData({
                firstName: '',
                lastName: '',
                phone: '',
                city: '',
                department: '',
                comment: ''
            });
            setIsLoading(false);
            setShowModal(true);
        } catch (error) {
            setIsLoading(false);
            console.error("Error creating order:", error);
            alert("Сталася помилка при створенні замовлення. Спробуйте ще раз.");
        }
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        
        // Очищаємо помилку при введенні
        if (errors[name]) {
            setErrors({...errors, [name]: ''});
        }
    };

    const nextStep = () => {
        setCurrentStep(2);
        window.scrollTo(0, 0);
    };

    const prevStep = () => {
        setCurrentStep(1);
        window.scrollTo(0, 0);
    };

    // Функція для отримання актуальної ціни (з урахуванням знижки)
    const getActualPrice = (product) => {
        return product.new_price ? product.new_price : product.price;
    };

    return (
        <>
            <Nav />
            <div className="basket">
                <div className="container">
                    <h3 className="basket__title title2">Кошик</h3>

                    {products && posts.data && currentStep === 1 && (
                        <div className="basket__wrapper">
                            <div className="basket__steps">
                                <div className="basket__step basket__step--active">
                                    <span className="basket__step-number">1</span>
                                    <span className="basket__step-title">Кошик</span>
                                </div>
                                <div className="basket__step-line"></div>
                                <div className="basket__step">
                                    <span className="basket__step-number">2</span>
                                    <span className="basket__step-title">Оформлення</span>
                                </div>
                            </div>

                            <div className="basket__content">
                                <div className="basket__products">
                                    <h4 className="basket__section-title">Ваші товари</h4>
                                    {products.map(({ product, size, quantity = 1 }, index) => {
                                        const dataProduct = posts.data.find((elem) => elem._id === product);
                                        return (
                                            <div key={index} className="basket__product">
                                                <ProductMin 
                                                    data={dataProduct} 
                                                    size={size} 
                                                    counter={true} 
                                                    price={price} 
                                                    setPrice={setPrice} 
                                                    products={products} 
                                                    setProducts={setProducts} 
                                                    quantity={quantity}
                                                    updateQuantity={(newQuantity) => updateQuantity(dataProduct._id, newQuantity)}
                                                />
                                                <button 
                                                    className="basket__delete" 
                                                    onClick={() => deleteProduct(dataProduct._id)}
                                                    aria-label="Видалити товар"
                                                >
                                                    <svg fill="#bbb" width="20" height="20" viewBox="0 0 485 485" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <g>
                                                                <rect x="67.224" width="350.535" height="71.81" />
                                                                <path d="M417.776,92.829H67.237V485h350.537V92.829H417.776z M165.402,431.447h-28.362V146.383h28.362V431.447z M256.689,431.447 h-28.363V146.383h28.363V431.447z M347.97,431.447h-28.361V146.383h28.361V431.447z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="basket__sum">
                                    <div className="basket__summary">
                                        <h4 className="basket__section-title">Ваше замовлення</h4>
                                        <div className="basket__cost">
                                            <p>Вартість товарів:</p>
                                            <p>{price} грн</p>
                                        </div>
                                        <div className="basket__delivery">
                                            <p>Доставка:</p>
                                            <p>За тарифами перевізника</p>
                                        </div>
                                        <div className="basket__payable">
                                            <p>До сплати: </p>
                                            <p>{price} грн</p>
                                        </div>
                                        <button 
                                            className="basket__pay basket__next"
                                            onClick={nextStep}
                                        >
                                            Оформити замовлення
                                        </button>
                                        <Link className="basket__continue-shopping" to="/shop">
                                            Продовжити покупки
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {products && posts.data && currentStep === 2 && (
                        <div className="basket__wrapper">
                            <div className="basket__steps">
                                <div className="basket__step basket__step--completed">
                                    <span className="basket__step-number">1</span>
                                    <span className="basket__step-title">Кошик</span>
                                </div>
                                <div className="basket__step-line basket__step-line--active"></div>
                                <div className="basket__step basket__step--active">
                                    <span className="basket__step-number">2</span>
                                    <span className="basket__step-title">Оформлення</span>
                                </div>
                            </div>

                            <div className="basket__content basket__content--checkout">
                                <div className="basket__checkout-form">
                                    <h4 className="basket__section-title">Контактна інформація</h4>
                                    <div className="basket__form">
                                        <div className="basket__form-group">
                                            <div className="basket__form-row">
                                                <div className="basket__form-field">
                                                    <label>Ім'я*</label>
                                                    <input 
                                                        type="text" 
                                                        name="firstName" 
                                                        placeholder="Введіть ім'я" 
                                                        value={formData.firstName} 
                                                        onChange={handleChange} 
                                                        className={errors.firstName ? "basket__form-error" : ""}
                                                    />
                                                    {errors.firstName && <span className="basket__error-message">{errors.firstName}</span>}
                                                </div>
                                                <div className="basket__form-field">
                                                    <label>Прізвище*</label>
                                                    <input 
                                                        type="text" 
                                                        name="lastName" 
                                                        placeholder="Введіть прізвище" 
                                                        value={formData.lastName} 
                                                        onChange={handleChange} 
                                                        className={errors.lastName ? "basket__form-error" : ""}
                                                    />
                                                    {errors.lastName && <span className="basket__error-message">{errors.lastName}</span>}
                                                </div>
                                            </div>
                                            <div className="basket__form-field">
                                                <label>Телефон*</label>
                                                <InputMask
                                                    mask="+38 (999) 999-99-99"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    maskChar="_"
                                                    className={errors.phone ? "basket__form-error" : ""}
                                                >
                                                    {(inputProps) => <input type="text" {...inputProps} name="phone" placeholder="+38 (___) ___-__-__" />}
                                                </InputMask>
                                                {errors.phone && <span className="basket__error-message">{errors.phone}</span>}
                                            </div>
                                        </div>
                                        
                                        <h4 className="basket__section-title">Доставка</h4>
                                        <div className="basket__form-group">
                                            <div className="basket__form-field">
                                                <label>Місто*</label>
                                                <CityAutocomplete
                                                    name="city"
                                                    placeholder="Введіть населений пункт"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    error={errors.city}
                                                />
                                            </div>
                                            <div className="basket__form-field">
                                                <label>Відділення*</label>
                                                <input 
                                                    type="text" 
                                                    name="department" 
                                                    placeholder="Введіть відділення" 
                                                    value={formData.department} 
                                                    onChange={handleChange} 
                                                    className={errors.department ? "basket__form-error" : ""}
                                                />
                                                {errors.department && <span className="basket__error-message">{errors.department}</span>}
                                            </div>
                                            <div className="basket__form-field">
                                                <label>Коментар до замовлення</label>
                                                <textarea 
                                                    name="comment" 
                                                    placeholder="Додаткова інформація щодо замовлення (Необов'язково)" 
                                                    value={formData.comment} 
                                                    onChange={handleChange} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="basket__sum">
                                    <div className="basket__summary basket__summary--checkout">
                                        <h4 className="basket__section-title">Ваше замовлення</h4>
                                        <div className="basket__order-products">
                                            {products.map(({ product, size, quantity = 1 }, index) => {
                                                const dataProduct = posts.data.find((elem) => elem._id === product);
                                                const actualPrice = dataProduct.new_price 
                                                    ? dataProduct.new_price 
                                                    : dataProduct.price;
                                                
                                                return (
                                                    <div key={index} className="basket__order-product">
                                                        <div className="basket__order-product-info">
                                                            <img 
                                                                src={dataProduct.images[0].startsWith('http') 
                                                                    ? dataProduct.images[0] 
                                                                    : `${API_BASE_URL}${dataProduct.images[0]}`} 
                                                                alt={dataProduct.title} 
                                                                className="basket__order-product-image" 
                                                            />
                                                            <div>
                                                                <p className="basket__order-product-title">{dataProduct.title}</p>
                                                                <p className="basket__order-product-size">Розмір: {size}</p>
                                                                {quantity > 1 && (
                                                                    <p className="basket__order-product-quantity">Кількість: {quantity}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="basket__order-product-price-container">
                                                            {dataProduct.new_price && (
                                                                <p className="basket__order-product-old-price">
                                                                    {dataProduct.price * quantity} грн
                                                                </p>
                                                            )}
                                                            <p className="basket__order-product-price">
                                                                {actualPrice * quantity} грн
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="basket__cost">
                                            <p>Вартість товарів:</p>
                                            <p>{price} грн</p>
                                        </div>
                                        <div className="basket__delivery">
                                            <p>Доставка:</p>
                                            <p>За тарифами перевізника</p>
                                        </div>
                                        <div className="basket__payable">
                                            <p>До сплати: </p>
                                            <p>{price} грн</p>
                                        </div>
                                        <div className="basket__checkout-buttons">
                                            <button 
                                                className="basket__back-button"
                                                onClick={prevStep}
                                            >
                                                Назад до кошика
                                            </button>
                                            <button 
                                                className="basket__pay"
                                                onClick={Order}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? "Оформлення..." : "Підтвердити замовлення"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!products && (
                        <div className="basket__empty">
                            <div>
                                <div className="basket__empty-icon">
                                    <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 7H20L19 21H5L4 7Z" stroke="#888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8 10V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V10" stroke="#888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <p className="basket__empty-text">
                                    Ваш кошик порожній
                                </p>
                                <p className="basket__empty-subtext">
                                    Час додати товари, які вам сподобались!
                                </p>
                                <Link className="basket__shop" to={'/shop'}>Перейти до каталогу</Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
            
            {/* Модальне вікно підтвердження замовлення */}
            <Modal show={showModal} onClose={() => {
                setShowModal(false);
                navigate('/shop');
            }}>
                <div className="order-success">
                    <div className="order-success__icon">
                        <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18457 2.99721 7.13633 4.39828 5.49707C5.79935 3.85782 7.69279 2.71538 9.79619 2.24015C11.8996 1.76491 14.1003 1.98234 16.07 2.86" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M22 4L12 14.01L9 11.01" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <h3 className="order-success__title">Дякуємо за замовлення!</h3>
                    <p className="order-success__text">
                        Ваше замовлення успішно оформлено. Ми зв'яжемося з вами найближчим часом для підтвердження деталей.
                    </p>
                    <button 
                        className="order-success__button"
                        onClick={() => {
                            setShowModal(false);
                            navigate('/shop');
                        }}
                    >
                        Продовжити покупки
                    </button>
                </div>
            </Modal>
        </>
    );
}