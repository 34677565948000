import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

import './nav.scss';

export const Nav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const { auth } = useSelector(state => state.auth);

    // Закривання меню при зміні розміру вікна
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768 && isMenuOpen) {
                setIsMenuOpen(false);
            }
        };

        // Зміна фону при скролі
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMenuOpen]);

    // Функція для закриття меню
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <nav className={`menu ${scrolled ? 'menu--scrolled' : ''}`}>
            <div className="container">
                <div className="menu__wrapper">
                    <Link className="menu__logo" to="/" onClick={closeMenu}> 
                        <svg width="100" height="50" viewBox="0 0 100 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {/* Ваш логотип SVG */}
                        </svg>
                    </Link>

                    <button 
                        className={`menu__toggle ${isMenuOpen ? 'menu__toggle--active' : ''}`} 
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label="Відкрити меню"
                    >
                        <span className="menu__toggle-line"></span>
                        <span className="menu__toggle-line"></span>
                        <span className="menu__toggle-line"></span>
                    </button>

                    <div className={`menu__container ${isMenuOpen ? 'menu__container--active' : ''}`}>
                        <ul className="menu__list">
                            <li className="menu__item">
                                <Link className="menu__link" to="/" onClick={closeMenu}>Головна</Link>
                            </li>
                            <li className="menu__item">
                                <Link className="menu__link" to="/shop" onClick={closeMenu}>Магазин</Link>
                            </li>
                            <li className="menu__item">
                                <Link className="menu__link" to="/about-us" onClick={closeMenu}>Про нас</Link>
                            </li>
                            <li className="menu__item">
                                <Link className="menu__link" to="/exchange" onClick={closeMenu}>Повернення</Link>
                            </li>
                            <li className="menu__item">
                                <Link className="menu__link" to="/payment" onClick={closeMenu}>Оплата і доставка</Link>
                            </li>
                            <li className="menu__item">
                                <Link className="menu__link" to="/contacts" onClick={closeMenu}>Контакти</Link>
                            </li>
                        </ul>

                        <ul className="menu__user">
                            <li className="menu__item">
                                <Link 
                                    className="menu__link menu__link--account" 
                                    to={!auth.data ? "/auth" : auth.data.role === 'client' ? "/account/client" : "/account/new-product"}
                                    onClick={closeMenu}
                                >
                                    <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_10_39)">
                                        <path d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM8.8375 22.85C9.375 21.725 12.65 20.625 15 20.625C17.35 20.625 20.6375 21.725 21.1625 22.85C19.4625 24.2 17.325 25 15 25C12.675 25 10.5375 24.2 8.8375 22.85ZM22.95 21.0375C21.1625 18.8625 16.825 18.125 15 18.125C13.175 18.125 8.8375 18.8625 7.05 21.0375C5.775 19.3625 5 17.275 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 17.275 24.225 19.3625 22.95 21.0375ZM15 7.5C12.575 7.5 10.625 9.45 10.625 11.875C10.625 14.3 12.575 16.25 15 16.25C17.425 16.25 19.375 14.3 19.375 11.875C19.375 9.45 17.425 7.5 15 7.5ZM15 13.75C13.9625 13.75 13.125 12.9125 13.125 11.875C13.125 10.8375 13.9625 10 15 10C16.0375 10 16.875 10.8375 16.875 11.875C16.875 12.9125 16.0375 13.75 15 13.75Z" fill="currentColor"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_10_39">
                                        <rect width="30" height="30" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <span className="menu__text">Аккаунт</span>
                                </Link>
                            </li>
                            <li className="menu__item">
                                <Link className="menu__link menu__link--basket" to="/basket" onClick={closeMenu}>
                                    <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_10_35)">
                                        <path d="M22.5 7.5H20C20 4.7375 17.7625 2.5 15 2.5C12.2375 2.5 10 4.7375 10 7.5H7.5C6.125 7.5 5 8.625 5 10V25C5 26.375 6.125 27.5 7.5 27.5H22.5C23.875 27.5 25 26.375 25 25V10C25 8.625 23.875 7.5 22.5 7.5ZM15 5C16.375 5 17.5 6.125 17.5 7.5H12.5C12.5 6.125 13.625 5 15 5ZM22.5 25H7.5V10H10V12.5C10 13.1875 10.5625 13.75 11.25 13.75C11.9375 13.75 12.5 13.1875 12.5 12.5V10H17.5V12.5C17.5 13.1875 18.0625 13.75 18.75 13.75C19.4375 13.75 20 13.1875 20 12.5V10H22.5V25Z" fill="currentColor"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_10_35">
                                        <rect width="30" height="30" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <span className="menu__text">Корзина</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};