import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import header_img from '../../image/header-logo.webp';
import useStructuredData from '../../hooks/useStructuredData';

import '../Shop/shop.scss'; // Ensure correct path to your shop styles or create tufli.scss

const Tufli = () => {
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const { posts, status } = useSelector(state => state.post);
    const type = "Туфлі";
    const [loading, setLoading] = useState(true);
    const [selectSort, setSelectSort] = useState("default");
    const [selectSize, setSelectSize] = useState("default");
    const [selectSeason, setSelectSeason] = useState("default");
    const [selectColor, setSelectColor] = useState("default");
    const [size, setSize] = useState([]);
    const [colors, setColors] = useState([]);
    const [hasSizes, setHasSizes] = useState(false);
    const [hasColors, setHasColors] = useState(false);
    const [hasSeasons, setHasSeasons] = useState(false);
    const [seasons, setSeasons] = useState([]);

    const seasonMap = useMemo(() => ({
        "summer": "Літні",
        "winter": "Зимові",
        "spring": "Демісезонні"
    }), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const applyFilter = useCallback(() => {
        if (!posts.data) return;

        let data = posts.data.filter(elem => elem.type === type);

        if (selectSize !== "default" && hasSizes) {
            data = data.filter(elem => elem.sizes.map(Number).includes(parseInt(selectSize)));
        }
        if (selectSeason !== "default" && hasSeasons) {
            data = data.filter(elem => elem.season === selectSeason);
        }
        if (selectColor !== "default" && hasColors) {
            data = data.filter(elem => elem.color === selectColor);
        }

        if (selectSort !== "default") {
            switch (selectSort) {
                case 'popular':
                    data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
                    break;
                case 'unpopular':
                    data = [...data].sort((elem, nextelem) => elem.popular - nextelem.popular);
                    break;
                case 'rich':
                    data = [...data].sort((elem, nextelem) => nextelem.price - elem.price);
                    break;
                case 'poor':
                    data = [...data].sort((elem, nextelem) => elem.price - nextelem.price);
                    break;
                default:
                    break;
            }
        }
        setFilterData(data);
    }, [posts.data, type, selectSize, hasSizes, selectSeason, hasSeasons, selectColor, hasColors, selectSort]);

    const searchProduct = useCallback((value) => {
        if (!posts.data) return;

        let data = posts.data.filter(item => item.type === type);
        if (value) {
            data = [...data].filter(elem =>
                elem.title.toLowerCase().includes(value.toLowerCase())
            );
        }
        setFilterData(data);
    }, [posts.data, type]);

     useEffect(() => {
        if (posts.data) {
            // Get available seasons
            const availableSeasonsSet = new Set(
                posts.data
                    .filter(item => item.type === type)
                    .map(item => item.season)
            );
            const availableSeasons = Array.from(availableSeasonsSet);

            const sizes = [];
            const colors = [];

            posts.data.filter(item => item.type === type).forEach(post => {
                post.sizes.forEach(size => {
                    if (!sizes.includes(size)) {
                        sizes.push(size);
                    }
                });
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
            });

            setHasSizes(sizes.length > 0);
            setHasColors(colors.length > 0);
            setHasSeasons(availableSeasons.length > 0);

            sizes.sort((a, b) => Number(a) - Number(b));
            colors.sort();
            setSeasons(availableSeasons); // Set only available seasons
            setSize(sizes);
            setColors(colors);
            setLoading(false);
        }
    }, [posts.data, type]);

    // useEffect для запуску applyFilter та searchProduct при зміні фільтрів
    useEffect(() => {
        applyFilter();
    }, [applyFilter, selectSort, selectSize, selectSeason, selectColor]);

    // useEffect для запуску searchProduct при зміні search
    useEffect(() => {
        searchProduct(search);
    }, [search, searchProduct]);

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        searchProduct(search);
    };

    const deleteFilter = () => {
        setSelectSort("default");
        setSelectSize("default");
        setSelectSeason("default");
        setSelectColor("default");
        setSearch("");
    };

     const structuredData = useStructuredData(filterData || (status === 'success' && posts.data ? posts.data.filter(item => item.type === type) : null));

    const metaDescription = useMemo(() => {
        let description = "Відкрийте для себе елегантну колекцію жіночих туфель в інтернет-магазині 'Взуттєва Мрія'. Купуйте класичні човники, зручні лофери, стильні оксфорди та витончені балетки за вигідними цінами. Великий вибір кольорів, матеріалів та розмірів. Доставка по Україні.";

        if (selectSize !== "default") {
            description += ` Розмір ${selectSize} в наявності.`;
        }
         if (selectSeason !== "default" && selectSeason in seasonMap) {
            description += ` Сезон ${seasonMap[selectSeason] || selectSeason}.`;
        }
        if (selectColor !== "default") {
            description += ` Колір ${selectColor} в асортименті.`;
        }
        if (search) {
            description += ` Пошук за запитом "${search}".`;
        }

        return description;
    }, [selectSize, selectSeason, selectColor, search, seasonMap]);

    const metaKeywords = useMemo(() => "туфлі жіночі, купити туфлі жіночі, жіночі туфлі Україна, туфлі лофери жіночі, туфлі на каблуку, туфлі човники, туфлі балетки, туфлі оксфорди жіночі, чорні туфлі жіночі, білі туфлі жіночі, бежеві туфлі жіночі, червоні туфлі жіночі, туфлі шкіряні жіночі, туфлі весна-літо, туфлі осінь, туфлі 2025, туфлі недорого, туфлі розпродаж, інтернет-магазин взуття", []);
    const metaTitle = useMemo(() => "Купити Жіночі Туфлі в Україні | Взуттєва Мрія", []);
    const headerTitle = useMemo(() => "Модні Жіночі Туфлі", []);
    const headerText = useMemo(() => "Оновіть свій гардероб вишуканими жіночими туфлями! У нашому каталозі представлені трендові моделі:  елегантні лофери, класичні човники, зручні балетки та стильні оксфорди.  Великий вибір кольорів, матеріалів та висоти каблука. Знайдіть свою ідеальну пару для будь-якої події!", []);


    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">{headerTitle}</h1>
                            {/* More descriptive and targeted header text */}
                            <p className="header__text">
                                {headerText}
                            </p>
                        </div>
                         <img src={header_img} alt="Модні жіночі туфлі різних стилів" className="header__img" loading="lazy" />
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                    <div className="filter">
                        <div className="filter__top">
                            <select name="sort" className="filter__sort" onChange={(event) => setSelectSort(event.target.value)} value={selectSort}>
                                <option value="default" disable="false" hidden>Сортувати за</option>
                                <option value="popular">Спочатку популярні</option>
                                <option value="unpopular">Спочатку не популярні</option>
                                <option value="rich">Спочатку дорогі</option>
                                <option value="poor">Спочатку дешеві</option>
                            </select>
                            {hasSizes && <select name="size" className="filter__size" onChange={(event) => setSelectSize(event.target.value)} value={selectSize}>
                                    <option value="default" disable="false" hidden>Розмір</option>
                                    {
                                        size.map((elem, index) => (
                                            <option key={index} value={elem}>{elem}</option>
                                        ))
                                    }
                                </select>}
                                 {hasSeasons && (
                                <select
                                    name="season"
                                    className="filter__season"
                                    onChange={(event) => setSelectSeason(event.target.value)}
                                    value={selectSeason}
                                >
                                    <option value="default" disable="false" hidden>Сезон</option>
                                    {seasons.map((season, index) => (
                                        <option key={index} value={season}>
                                            {seasonMap[season] || season}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {hasColors && <select name="color" className="filter__color" onChange={(event) => setSelectColor(event.target.value)} value={selectColor}>
                                    <option value="default" disable="false" hidden>Колір</option>
                                    {
                                        colors.map((elem, index) => (
                                            <option key={index} value={elem}>{elem}</option>
                                        ))
                                    }
                                </select>}
                        </div>
                         <div className="filter__bottom">
                           <button className="filter__delete" onClick={deleteFilter}>Скинути фільтри</button>
                             <div className="filter__search">
                                <button className="filter__search-btn" onClick={handleSearchButtonClick}></button>
                                <input type="text" className="filter__search-input" placeholder='Пошук' value={search} onChange={handleSearchInputChange} aria-label="Поле для пошуку туфель" />
                            </div>
                        </div>
                    </div>
                     {loading ? <Spiner /> :
                       <Pagination items={filterData || (posts.data ? posts.data.filter(item => item.type === type) : [])}
                            renderImage={(src) => (
                                <img src={src} alt="Жіночі туфлі - широкий вибір моделей" loading="lazy" />
                            )}
                        />
                      }
                </div>
            </div>

            <div className="seo-text-block">
                <div className="container">
                    <div className="row">
                        <h2 style={{ textAlign: 'center' }}>Купити жіночі туфлі в інтернет-магазині "Взуттєва Мрія" - тренди 2025 року</h2>
                        <p style={{ textAlign: 'justify' }}>Шукаєте ідеальні <strong>жіночі туфлі</strong>, що поєднують елегантність, комфорт та актуальні модні тенденції? Інтернет-магазин "Взуттєва Мрія" радий представити вам колекцію <strong>жіночих туфель 2025</strong> року! У нас ви знайдете широкий вибір моделей на будь-який смак: від класичних <strong>чорних туфель жіночих</strong> для офісу до трендових та зручних <strong>жіночих лоферів</strong> для повсякденного стилю.  Підкресліть свою жіночність та бездоганний смак з нашою колекцією <strong>жіночих туфель</strong>!</p>

                        <h2 style={{ textAlign: 'center' }}>Тренди та різноманіття жіночих туфель від "Взуттєвої Мрії"</h2>
                        <p style={{ textAlign: 'justify' }}>Ознайомтеся з різноманіттям <strong>жіночих туфель</strong>, представлених у нашому інтернет-магазині:</p>
                        <ul>
                            <li> <strong><em>Класичні туфлі на підборах та човники:</em></strong> <em>Нестаріюча класика, що повинна бути в гардеробі кожної жінки. <strong>Туфлі на каблуку жіночі</strong> та <strong>туфлі човники жіночі</strong> додадуть вам впевненості та витонченості на будь-якому заході.</em></li>
                            <li> <strong><em>Зручні жіночі лофери та оксфорди:</em></strong> <em>Для тих, хто цінує комфорт та стиль. <strong>Лофери жіночі купити</strong> або <strong>жіночі оксфорди</strong> – це забезпечити собі зручність на цілий день, не втрачаючи при цьому актуальності образу.</em></li>
                             <li> <strong><em>Балетки – вибір на кожен день:</em></strong> <em>Легкі, зручні та жіночні <strong>балетки жіночі</strong> стануть незамінним взуттям для активних жінок. <strong>Купити балетки жіночі</strong> – це інвестувати в свій комфорт.</em></li>
                            <li> <strong><em>Кольорова гама:</em></strong> <em>Від класичних <strong>чорних туфель жіночих</strong> та елегантних <strong>бежевих туфель жіночих</strong> до яскравих <strong>червоних туфель жіночих</strong> та свіжих <strong>білих туфель жіночих</strong> – у нас ви знайдете туфлі будь-якого кольору під ваш настрій та образ. Особливо популярні <strong>білі туфлі жіночі</strong>, що додають легкості та вишуканості.</em></li>
                            <li> <strong><em>Різноманіття матеріалів:</em></strong> <strong><em>Шкіряні туфлі жіночі</em></strong>, <strong>замшеві туфлі жіночі</strong>, лакові моделі та текстильні варіанти – обирайте матеріал, що відповідає вашим уподобанням та сезону. <strong>Туфлі жіночі з натуральної шкіри</strong> – це гарантія якості та довговічності.</li>
                        </ul>

                        <h3 style={{ textAlign: 'center' }}>Вигідно купити жіночі туфлі в Україні: акції та розпродажі</h3>
                        <p style={{ textAlign: 'justify' }}>Не пропустіть можливість <strong>купити туфлі жіночі недорого</strong> в інтернет-магазині "Взуттєва Мрія"!  Слідкуйте за нашими постійними акціями та <strong>розпродажами жіночих туфель</strong>, щоб придбати взуття своєї мрії за найвигіднішою ціною.  Ми пропонуємо широкий асортимент якісних та модних <strong>жіночих туфель</strong> з швидкою доставкою по всій Україні. Оновіть свій взуттєвий гардероб разом з "Взуттєвою Мрією" вже сьогодні!</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Tufli;