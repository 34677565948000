import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Thumbs } from 'swiper/modules'; // Controller видалено, оскільки не використовувався
import { useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../axios';
import InnerHTML from 'dangerously-set-html-content';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import { Helmet } from "react-helmet";
import { format, intervalToDuration } from 'date-fns';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './cart.scss';

import { Nav, Footer, ProductSlider, Spiner } from '../../components/';
import { selectIsAuth } from "../../redux/slices/auth";
import Modal from '../../components/Modal';
import useStructuredData from '../../hooks/useStructuredData';

const ProductImages = ({ images, setThumbsSwiper, mainSwiper, thumbsSwiper }) => { // setMainSwiper видалено, оскільки не використовується безпосередньо тут
    const [initialized, setInitialized] = useState(false);
    const handleThumbsSwiper = useCallback((swiper) => {
        setThumbsSwiper(swiper);
    }, [setThumbsSwiper]);

    useEffect(() => {
        if (thumbsSwiper && !initialized) {
            setInitialized(true);
        }
    }, [thumbsSwiper, initialized]);

    return (
        <div className="cart__photos">
            <div className="cart__main-photo">
                <Swiper
                    modules={[Navigation, EffectFade, Thumbs]} // Controller видалено
                    effect="fade"
                    slidesPerView={1}
                    navigation
                    thumbs={{ swiper: initialized ? thumbsSwiper : null }}
                    className="product-swiper" // Додано клас для стилізації Swiper
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={`https://api.shoedream.com.ua${image}`} alt="Товар" className="cart__img" loading="lazy" />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {images.length > 1 && ( // Показувати мініатюри лише якщо є більше ніж одне зображення
                <div className="cart__thumbs">
                    <Swiper
                        onSwiper={handleThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={'auto'} // Змінено на auto для гнучкості
                        modules={[Thumbs]}
                        breakpoints={{
                            500: { slidesPerView: 'auto', },
                            768: { slidesPerView: 'auto', },
                            991: { slidesPerView: 'auto', }
                        }}
                        className="thumbs-swiper" // Додано клас для стилізації Swiper мініатюр
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="cart__thumb-wrapper"> {/* Обгортка для мініатюр */}
                                    <img src={`https://api.shoedream.com.ua${image}`} alt="Товар" className="cart__img-thumb" loading="lazy" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </div>
    );
};


const ProductInfo = ({ product, size, setSize, addToCart }) => {
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isSaleActive, setIsSaleActive] = useState(false);

    useEffect(() => {
        if (product && product.sale_start && product.sale_end) {
            const calculateTimeRemaining = () => {
                const saleEnd = new Date(product.sale_end);
                const now = new Date();

                if (saleEnd > now) {
                    const duration = intervalToDuration({ start: now, end: saleEnd });
                    setTimeRemaining({
                        days: duration.days,
                        hours: duration.hours,
                        minutes: duration.minutes,
                        seconds: duration.seconds,
                    });
                    setIsSaleActive(true);
                } else {
                    setTimeRemaining(null);
                    setIsSaleActive(false);
                }
            };

            calculateTimeRemaining();
            const intervalId = setInterval(calculateTimeRemaining, 1000);

            return () => clearInterval(intervalId);
        }
        setIsSaleActive(false);
    }, [product]);

    return (
        <div className="cart__data">
            <h2 className="cart__title">{product.title}</h2> {/* Змінено на h2 для семантики */}
            <p className="cart__brand">{product.brand}</p>
            <div className='cart__prices'>
                <p className="cart__price cart__new-price">{product.discount ? product.new_price : product.price} <span>грн</span></p> {/* Стилізована ціна */}
                {product.discount && <p className="cart__price cart__old-price">{product.price} <span>грн</span></p>}
                {isSaleActive && (
                    <div className="sale-countdown">
                        <div className="sale-label">Акція</div>
                        {timeRemaining.days > 0 && <span>{timeRemaining.days} дн. </span>}
                        {timeRemaining.hours > 0 && <span>{timeRemaining.hours} год. </span>}
                        {timeRemaining.minutes > 0 && <span>{timeRemaining.minutes} хв. </span>}
                        <span>{timeRemaining.seconds} сек.</span>
                    </div>
                )}
            </div>


            <div className="cart__sizes-section"> {/* Розділ для розмірів */}
                <p className="cart__text">Оберіть розмір:</p>
                <div className="cart__sizes">
                    {product.sizes.map((sizeItem, index) => (
                        <div key={index} className="cart__size-item"> {/* Обгортка для кожного розміру */}
                            <input className='cart__radio' type="radio" name="size" value={sizeItem} id={`size${sizeItem}`} onClick={(event) => setSize(event.target.value)} />
                            <label className="cart__size" htmlFor={`size${sizeItem}`}>{sizeItem}</label>
                        </div>
                    ))}
                </div>
                {size === 'error' && <p className="error">Будь ласка, оберіть розмір</p>}
            </div>
            <button className="cart__basket" onClick={addToCart}>Додати до кошика</button>
        </div>
    );
};

const ProductDescription = ({ description }) => (
    <div className="description">
        <div className="container">
            <h3 className="description__title">Опис</h3> {/* Змінено стиль заголовка */}
            <div className="description__text">
                <InnerHTML html={description} />
            </div>
        </div>
    </div>
);

const ProductDetails = ({ product }) => {
    const getSeasonText = (season) => {
        switch (season) {
            case "summer": return "Літо";
            case "winter": return "Зима";
            default: return "Весна-осінь";
        }
    };

    const detailsData = [
        { name: "Тип товару", value: product.type },
        { name: "Матеріал верху", value: product.material_top },
        { name: "Матеріал підкладки", value: product.material_substrate },
        { name: "Устілка", value: product.material_sole },
        { name: "Сезон", value: getSeasonText(product.season) },
        { name: "Колір", value: product.color },
        { name: "Тип підошви", value: product.soletype },
        product.heel_size && { name: "Висота каблука", value: product.heel_size },
        product.platform_size && { name: "Висота платформи", value: product.platform_size },
        { name: "Країна виробник", value: product.country },
        { name: "Бренд", value: product.brand },
        { name: "Артикул", value: product.article },
    ].filter(Boolean); // Фільтруємо null/undefined значення

    return (
        <div className="detail">
            <div className="container">
                <h3 className="detail__title">Деталі</h3>
                <div className="detail__wrapper">
                    {detailsData.map((detail, index) => (
                        <div className="detail__item" key={index}>
                            <span className="detail__name">{detail.name}</span>
                            <span className="detail__text">{detail.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const RatingStars = ({ count }) => (
    <div className="review__stars">
        {new Array(parseInt(count)).fill(undefined).map((_, index) => (
            <svg key={index} className="star-icon" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> {/* Додано клас star-icon */}
                <path d="M10.43 6.00001L8.95996 1.16001C8.66996 0.21001 7.32996 0.21001 7.04996 1.16001L5.56996 6.00001H1.11996C0.149959 6.00001 -0.250041 7.25001 0.539959 7.81001L4.17996 10.41L2.74996 15.02C2.45996 15.95 3.53996 16.7 4.30996 16.11L7.99996 13.31L11.69 16.12C12.46 16.71 13.54 15.96 13.25 15.03L11.82 10.42L15.46 7.82001C16.25 7.25001 15.85 6.01001 14.88 6.01001H10.43V6.00001Z" fill="#FFFD54" />
            </svg>
        ))}
        {new Array(5 - count).fill(undefined).map((_, index) => (
            <svg key={index} className="star-icon outline" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"> {/* Додано класи star-icon та outline */}
                <path d="M16.6498 6.04L11.8098 5.62L9.91983 1.17C9.57983 0.36 8.41983 0.36 8.07983 1.17L6.18983 5.63L1.35983 6.04C0.479829 6.11 0.119828 7.21 0.789828 7.79L4.45983 10.97L3.35983 15.69C3.15983 16.55 4.08983 17.23 4.84983 16.77L8.99983 14.27L13.1498 16.78C13.9098 17.24 14.8398 16.56 14.6398 15.7L13.5398 10.97L17.2098 7.79C17.8798 7.21 17.5298 6.11 16.6498 6.04ZM8.99983 12.4L5.23983 14.67L6.23983 10.39L2.91983 7.51L7.29983 7.13L8.99983 3.1L10.7098 7.14L15.0898 7.52L11.7698 10.4L12.7698 14.68L8.99983 12.4Z" fill="white" />
            </svg>
        ))}
    </div>
);

const ReviewItem = ({ comment, converDate }) => (
    <div className="review__item">
        <div className="review__header"> {/*  Заголовок відгуку */}
            <p className="review__user-name">{comment.user.lastname} {comment.user.name}</p>
            <RatingStars count={comment.count_star} />
        </div>
        <p className="review__text">{comment.data}</p>
        <p className="review__date">{converDate(comment.createdAt)}</p>
    </div>
);

const ReviewForm = ({ onSubmit, error, register, errors, reset, setReview }) => (
    <form className="review__new" onSubmit={onSubmit}>
        <h4 className="review__form-title">Залишити відгук</h4> {/* Заголовок форми */}
        {error && <p className="error review__error">{error}</p>}
        <div className="rating">
            {[5, 4, 3, 2, 1].map((item) => (
                <React.Fragment key={item}>
                    <input type="radio" id={`rating${item}`} className="rating__radio" value={item} {...register('count_star', { required: 'Оцініть товар' })} />
                    <label htmlFor={`rating${item}`} className="rating__star">
                        <svg className="star-icon" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> {/* Додано клас star-icon */}
                            <path d="M10.43 6.00001L8.95996 1.16001C8.66996 0.21001 7.32996 0.21001 7.04996 1.16001L5.56996 6.00001H1.11996C0.149959 6.00001 -0.250041 7.25001 0.539959 7.81001L4.17996 10.41L2.74996 15.02C2.45996 15.95 3.53996 16.7 4.30996 16.11L7.99996 13.31L11.69 16.12C12.46 16.71 13.54 15.96 13.25 15.03L11.82 10.42L15.46 7.82001C16.25 7.25001 15.85 6.01001 14.88 6.01001H10.43V6.00001Z" fill="#ffffff" />
                        </svg>
                    </label>
                </React.Fragment>
            ))}
        </div>
        <p className="error"><ErrorMessage errors={errors} name="rating" /></p>
        <textarea className="review__content" id="reviewContent" rows="5" placeholder='Ваш відгук про товар' {...register('data', { required: 'Напишіть відгук' })}></textarea> {/* Змінено placeholder */}
        <p className="error"><ErrorMessage errors={errors} name="review" /></p>
        <div className="review__buttons"> {/* Кнопки в окремому блоці */}
            <button type="submit" className="review__save">Опублікувати</button>
            <button type="button" className="review__close" onClick={() => setReview(false)}>
                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M52.1999 1.84001C50.6399 0.280006 48.1199 0.280006 46.5599 1.84001L26.9999 21.36L7.43988 1.8C5.87988 0.240005 3.35988 0.240005 1.79988 1.8C0.239883 3.36 0.239883 5.88001 1.79988 7.44001L21.3599 27L1.79988 46.56C0.239883 48.12 0.239883 50.64 1.79988 52.2C3.35988 53.76 5.87988 53.76 7.43988 52.2L26.9999 32.64L46.5599 52.2C48.1199 53.76 50.6399 53.76 52.1999 52.2C53.7599 50.64 53.7599 48.12 52.1999 46.56L32.6399 27L52.1999 7.44001C53.7199 5.92001 53.7199 3.36001 52.1999 1.84001V1.84001Z" fill="black" />
                </svg>
            </button>
        </div>
    </form>
);

const ProductReviews = ({ comments, openNewReview, review, setReview, error, handleSubmit, register, errors, reset, converDate, countComments, setCountComments }) => (
    <div className="review">
        <div className="container">
            <div className="review__header-section"> {/* Заголовок секції відгуків */}
                <h3 className="review__title">Відгуки</h3>
                <button className="review__new-open" onClick={openNewReview}>Залишити відгук</button> {/* Змінено на button */}
            </div>
            {review && <ReviewForm
                onSubmit={handleSubmit}
                error={error}
                register={register}
                errors={errors}
                reset={reset}
                setReview={setReview}
            />}
            {comments &&
                <React.Fragment>
                    {comments.slice(0, countComments).map((comment) => (
                        <ReviewItem key={comment._id} comment={comment} converDate={converDate} />
                    ))}
                    {comments.length > countComments && <button className="review__more" onClick={() => setCountComments(countComments + 3)}>Показати більше відгуків</button>}
                </React.Fragment>
            }
        </div>
    </div>
);

const ProductRecommendations = ({ posts, productData }) => {
    const memoizedRecommendations = useMemo(() => {
        if (!posts?.data || !productData) {
            return null;
        }
        return posts.data
            .filter((post) => post.season === productData.season)
            .sort((post, nextpost) => nextpost.popular - post.popular)
            .slice(0, 4); // Зменшено кількість рекомендацій для мінімалізму
    }, [posts, productData]);

    if (!memoizedRecommendations || memoizedRecommendations.length === 0) { // Перевірка на наявність рекомендацій
        return null; // Не відображати секцію, якщо немає рекомендацій
    }

    return (
        <div className="recommend">
            <div className="container">
                <h3 className="recommend__title">Рекомендовані товари</h3>
                <div className="recommend__slider"> {/* Змінено клас для слайдера рекомендацій */}
                    <ProductSlider data={memoizedRecommendations} />
                </div>
            </div>
        </div>
    );
};


export const ProductCart = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const { slug } = useParams();
    const { posts } = useSelector(state => state.post);
    const { auth } = useSelector(state => state.auth);
    const isAuth = useSelector(selectIsAuth);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [mainSwiper, /*setMainSwiper не використовується*/] = useState(null); // setMainSwiper видалено, щоб уникнути невикористання
    const [review, setReview] = useState(false);
    const [productData, setProductData] = useState(null);
    const [comments, setComments] = useState(null);
    const [countComments, setCountComments] = useState(3);
    const [error, setError] = useState("");
    const [size, setSize] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        setProductData(null);
        if (slug) {
            window.scrollTo(0, 0);
            Promise.all([
                axios.get(`/posts/${slug}`),
                axios.get(`/comment/${slug}`)
            ])
                .then(([{ data: productData }, { data: commentsData }]) => {
                    setProductData(productData);
                    setComments(commentsData.comments);
                });
        }
    }, [slug]);

    const reviewSave = useCallback(async (values) => {
        try {
            const response = await axios.post(`/comment/${slug}`, values);
            if (response.status === 200) {
                values["user"] = {
                    lastname: auth.data.lastname,
                    name: auth.data.name,
                };
                setComments([...comments, values]);
                setReview(false);
                reset();
            } else {
                setError("Помилка збереження відгуку");
            }

        } catch (err) {
            setError("Помилка збереження відгуку");
            console.error('Error saving review:', err);
        }
    }, [auth, comments, reset, slug]);

    const openNewReview = useCallback(() => {
        if (isAuth) {
            setReview(true);
            reset();
        } else {
            navigate("/auth");
        }
    }, [isAuth, navigate, reset]);

    const converDate = useCallback((timestamp) => {
        const date = new Date(timestamp);
        return moment(date).format("DD.MM.YYYY");
    }, []);

    const addToCart = useCallback(() => {
        if (size && size !== 'error') {
            const data = {
                product: productData._id,
                size,
                price: productData.discount ? productData.new_price : productData.price,
                count: 1,
            };

            const basket = JSON.parse(window.localStorage.getItem('basket')) || [];
            const existingProductIndex = basket.findIndex((item) => item.product === productData._id);

            if (existingProductIndex >= 0) {
                basket[existingProductIndex] = data;
            } else {
                basket.push(data);
            }

            window.localStorage.setItem('basket', JSON.stringify(basket));
            setShowModal(true);
        } else {
            setSize('error');
        }
    }, [productData, size, setSize, setShowModal]);

    const structuredData = useStructuredData(productData);

    return (
        <React.Fragment>
            <Helmet>
                <title>{productData ? `${productData.title} | Взуттєва Мрія` : 'Взуттєва Мрія'}</title>
                <meta name="description" content={productData ? `Купуйте ${productData.title} в інтернет-магазині Взуттєва Мрія за вигідними цінами ⭐ Найкращі пропозиції ✓ Повернення протягом 14 днів ⏩ Доставка по Україні ✔️ Оплата при отриманні ➤ В нас вигідніше!` : 'ShoeDream - ваш інтернет-магазин жіночого взуття.'} />
                <meta name="keywords" content="магазин, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі, знижки, скидки, жіноче взуття, женская обувь" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={productData ? `${productData.title} | Взуттєва Мрія — купити взуття недорого ▶️ Низькі Ціни в Україні.` : 'Взуттєва Мрія'} />
                <link rel="icon" href="/favicon.ico" />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>

            <Nav />

            {productData && (
                <React.Fragment>
                    <header className="cart">
                        <div className="container">
                            <div className="cart__wrapper">
                                <ProductImages images={productData.images}
                                    setThumbsSwiper={setThumbsSwiper}
                                    mainSwiper={mainSwiper}
                                    thumbsSwiper={thumbsSwiper}
                                />
                                <ProductInfo
                                    product={productData}
                                    size={size}
                                    setSize={setSize}
                                    addToCart={addToCart}
                                />
                            </div>
                        </div>
                    </header>
                    <ProductDescription description={productData.description} />
                    <ProductDetails product={productData} />
                    <ProductReviews
                        comments={comments}
                        openNewReview={openNewReview}
                        review={review}
                        setReview={setReview}
                        error={error}
                        handleSubmit={handleSubmit(reviewSave)}
                        register={register}
                        errors={errors}
                        reset={reset}
                        converDate={converDate}
                        countComments={countComments}
                        setCountComments={setCountComments}
                    />
                    <ProductRecommendations posts={posts} productData={productData} />
                </React.Fragment>
            )}

            {!productData && <Spiner />}

            <Footer />

            <Modal show={showModal} onClose={() => setShowModal(false)} productData={productData} size={size}>
                {/* ... вміст модалки ... */}
            </Modal>
        </React.Fragment>
    );
};