import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './product.scss';

export const ProductMin = ({ data, size, counter, price, setPrice, products, setProducts, quantity: initialQuantity, updateQuantity }) => {
    const [count, setCount] = useState(initialQuantity || 1);
    const [currentPrice, setCurrentPrice] = useState(parseInt(data.discount ? data.new_price : data.price) * (initialQuantity || 1));

    useEffect(() => {
        setCurrentPrice(parseInt(data.discount ? data.new_price : data.price) * count);
    }, [count, data.discount, data.new_price, data.price]);

    const handleQuantityChange = (newQuantity) => {
        setCount(newQuantity);
        updateQuantity(newQuantity);
    };

    const minusCount = () => {
        if (count > 1) {
            const newQuantity = count - 1;
            handleQuantityChange(newQuantity);
            setPrice(price - parseInt(data.discount ? data.new_price : data.price));
        }
    };

    const addCount = () => {
        const newQuantity = count + 1;
        handleQuantityChange(newQuantity);
        setPrice(price + parseInt(data.discount ? data.new_price : data.price));
    };

    return (
        <>
            {data && <>
                <img src={`https://api.shoedream.com.ua${data.images[0]}`} alt="product" loading="lazy" className="product__img product__img-small" />
                <div className="product__info">
                    <Link className="product__name title2" to={`/shop/${data._id}`}>{data.title}</Link>
                    <p className="product__brand">{data.brand}</p>
                    {size && <p className="product__size">Розмір: {size}</p>}
                    {counter && <div className="count">
                        <button className="minus" onClick={minusCount}>
                            <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line y1="1" x2="14" y2="1" stroke="white" strokeWidth="2" />
                            </svg>
                        </button>
                        <input type="text" disabled value={count} className="counter" />
                        <button className="add" onClick={addCount}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
                            </svg>
                        </button>
                    </div>}
                    <p className="product__price"><span>{currentPrice}</span> грн</p>
                </div>
            </>}
        </>
    )
}