import React, { useState, useEffect, useRef } from "react";

const CityAutocomplete = ({ value, onChange, error, name, placeholder }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  useEffect(() => {
    // Закриття списку при кліку поза компонентом
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const debouncedSearch = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onChange({ target: { name, value } });

    if (debouncedSearch.current) {
      clearTimeout(debouncedSearch.current);
    }

    if (value.length > 2) {
      setLoading(true);
      debouncedSearch.current = setTimeout(() => {
        fetchCitySuggestions(value);
      }, 300);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const fetchCitySuggestions = async (query) => {
    try {
      // Параметри запиту для пошуку тільки населених пунктів України
      const params = new URLSearchParams({
        q: query,
        format: "json",
        countrycodes: "ua", // ISO код України
        limit: 5,
        addressdetails: 1,
        "accept-language": "uk",
        featuretype: "settlement" // Шукаємо тільки населені пункти
      });

      const response = await fetch(`https://nominatim.openstreetmap.org/search?${params}`);
      const data = await response.json();

      // Фільтруємо та форматуємо результати
      const cities = data
        .filter(item => {
          // Перевіряємо що це місто, село, смт, тощо
          return (
            item.type === "city" || 
            item.type === "town" || 
            item.type === "village" || 
            item.type === "hamlet" ||
            (item.type === "administrative" && item.class === "boundary")
          );
        })
        .map(item => {
          let cityName = item.display_name.split(", ")[0];
          let regionName = "";
          
          // Спробуємо знайти назву області
          if (item.address) {
            if (item.address.state) {
              regionName = item.address.state;
            } else if (item.address.county) {
              regionName = item.address.county;
            }
          }
          
          return {
            id: item.place_id,
            name: cityName,
            fullName: regionName ? `${cityName}, ${regionName}` : cityName,
            displayName: item.display_name
          };
        });

      setSuggestions(cities);
      setShowSuggestions(cities.length > 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
      setLoading(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setInputValue(suggestion.fullName);
    onChange({ target: { name, value: suggestion.fullName } });
    setSuggestions([]);
    setShowSuggestions(false);
  };

  return (
    <div className="basket__autocomplete-wrapper" ref={wrapperRef}>
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => inputValue.length > 2 && suggestions.length > 0 && setShowSuggestions(true)}
        className={error ? "basket__form-error" : ""}
        autoComplete="off"
      />
      {loading && (
        <div className="basket__autocomplete-loader">
          <div className="spinner"></div>
        </div>
      )}
      {showSuggestions && (
        <ul className="basket__autocomplete-suggestions">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              onClick={() => handleSelectSuggestion(suggestion)}
              className="basket__autocomplete-suggestion"
            >
              {suggestion.fullName}
            </li>
          ))}
        </ul>
      )}
      {error && <span className="basket__error-message">{error}</span>}
    </div>
  );
};

export default CityAutocomplete;