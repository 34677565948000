import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import header_img from '../../image/header-logo.webp';
import useStructuredData from '../../hooks/useStructuredData';
import debounce from 'lodash/debounce';

import './botilioni.scss';

// Компонент EmptyState
const EmptyState = ({ message, actionText, onAction }) => {
    return (
        <div className="empty-state">
            <h3>{message}</h3>
            {actionText && onAction && (
                <button onClick={onAction} className="empty-state__button" aria-label={actionText}>
                    {actionText}
                </button>
            )}
        </div>
    );
};

// Компонент для відображення інформації про кількість товарів
const ProductCount = ({ count, totalCount }) => {
    return (
        <div className="product-count">
            Знайдено товарів: <strong>{count}</strong> {count !== totalCount && `(з ${totalCount})`}
        </div>
    );
};

// Компонент для відображення активних фільтрів
const ActiveFilters = ({ filters, filterOptions, onReset, onRemoveSingleFilter, seasonMap }) => {
    const { selectSize, selectSeason, selectColor } = filters;

    // Перевіряємо, чи є активні фільтри
    const hasActiveFilters = selectSize !== "default" ||
        selectSeason !== "default" ||
        selectColor !== "default";

    if (!hasActiveFilters) return null;

    // Функція для отримання назви сезону
    const getSeasonName = (season) => {
        return seasonMap[season] || season;
    };

    return (
        <div className="active-filters">
            <div className="active-filters__title">Активні фільтри:</div>
            <div className="active-filters__tags">
                {selectSize !== "default" && (
                    <div className="active-filters__tag">
                        Розмір: {selectSize}
                        <button onClick={() => onRemoveSingleFilter('selectSize')} aria-label="Видалити фільтр розміру">×</button>
                    </div>
                )}
                {selectSeason !== "default" && (
                    <div className="active-filters__tag">
                        Сезон: {getSeasonName(selectSeason)}
                        <button onClick={() => onRemoveSingleFilter('selectSeason')} aria-label="Видалити фільтр сезону">×</button>
                    </div>
                )}
                {selectColor !== "default" && (
                    <div className="active-filters__tag">
                        Колір: {selectColor}
                        <button onClick={() => onRemoveSingleFilter('selectColor')} aria-label="Видалити фільтр кольору">×</button>
                    </div>
                )}
                <button className="active-filters__clear" onClick={onReset} aria-label="Скинути всі фільтри">
                    Скинути все
                </button>
            </div>
        </div>
    );
};

// Компонент фільтрів
const FilterPanel = ({
    filterOptions,
    filterValues,
    onFilterChange,
    onReset,
    searchValue,
    onSearchChange,
    onSearchSubmit,
    seasonMap
}) => {
    const { sizes, colors, seasons } = filterOptions;
    const { selectSort, selectSize, selectSeason, selectColor } = filterValues;
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

    return (
        <div className="filter">
            <div className="filter__container">
                <button className="filter__mobile-toggle" onClick={toggleFilter} aria-label="Відкрити/Закрити фільтри">
                    <span>Фільтри</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                    </svg>
                </button>

                <div className={`filter__wrapper ${isFilterOpen ? 'filter__wrapper--open' : ''}`}>
                    <div className="filter__top">
                        <select
                            name="sort"
                            className="filter__select"
                            onChange={(e) => onFilterChange('selectSort', e.target.value)}
                            value={selectSort}
                            aria-label="Сортувати товари"
                        >
                            <option value="default" disabled hidden>Сортувати за</option>
                            <option value="popular">Спочатку популярні</option>
                            <option value="unpopular">Спочатку не популярні</option>
                            <option value="rich">Спочатку дорогі</option>
                            <option value="poor">Спочатку дешеві</option>
                        </select>

                        {sizes.length > 0 && (
                            <select
                                name="size"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectSize', e.target.value)}
                                value={selectSize}
                                aria-label="Фільтрувати за розміром"
                            >
                                <option value="default" disabled hidden>Розмір</option>
                                {sizes.map((size, index) => (
                                    <option key={index} value={size}>{size}</option>
                                ))}
                            </select>
                        )}

                        {seasons.length > 0 && (
                            <select
                                name="season"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectSeason', e.target.value)}
                                value={selectSeason}
                                aria-label="Фільтрувати за сезоном"
                            >
                                <option value="default" disabled hidden>Сезон</option>
                                {seasons.map((season, index) => (
                                    <option key={index} value={season}>{seasonMap[season]}</option>
                                ))}
                            </select>
                        )}

                        {colors.length > 0 && (
                            <select
                                name="color"
                                className="filter__select"
                                onChange={(e) => onFilterChange('selectColor', e.target.value)}
                                value={selectColor}
                                aria-label="Фільтрувати за кольором"
                            >
                                <option value="default" disabled hidden>Колір</option>
                                {colors.map((color, index) => (
                                    <option key={index} value={color}>{color}</option>
                                ))}
                            </select>
                        )}
                    </div>

                    <div className="filter__bottom">
                        <button
                            className="filter__reset"
                            onClick={onReset}
                            aria-label="Скинути всі фільтри"
                        >
                            Скинути
                        </button>
                    </div>
                </div>

                <div className="filter__search">
                    <input
                        type="text"
                        className="filter__search-input"
                        placeholder="Пошук товарів"
                        value={searchValue}
                        onChange={onSearchChange}
                        aria-label="Пошук товарів за назвою"
                    />
                    <button
                        className="filter__search-btn"
                        onClick={onSearchSubmit}
                        aria-label="Пошук товарів"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="11" cy="11" r="8" />
                            <line x1="21" y1="21" x2="16.65" y2="16.65" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

const Botilioni = () => {
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const { posts, status } = useSelector(state => state.post);
    const type = "Ботильйони";
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        selectSort: "default",
        selectSize: "default",
        selectSeason: "default",
        selectColor: "default"
    });
    const [filterOptions, setFilterOptions] = useState({
        sizes: [],
        colors: [],
        seasons: []
    });

    const seasonMap = useMemo(() => ({
        "summer": "Літні",
        "winter": "Зимові",
        "spring": "Демісезонні"
    }), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const applyFilter = useCallback(() => {
        if (!posts.data) return;

        let data = posts.data.filter(elem => elem.type === type);

        if (filters.selectSize !== "default" && filterOptions.sizes.length > 0) {
            data = data.filter(elem => elem.sizes.map(Number).includes(parseInt(filters.selectSize)));
        }
        if (filters.selectSeason !== "default" && filterOptions.seasons.length > 0) {
            data = data.filter(elem => elem.season === filters.selectSeason);
        }
        if (filters.selectColor !== "default" && filterOptions.colors.length > 0) {
            data = data.filter(elem => elem.color === filters.selectColor);
        }

        if (filters.selectSort !== "default") {
            switch (filters.selectSort) {
                case 'popular':
                    data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
                    break;
                case 'unpopular':
                    data = [...data].sort((elem, nextelem) => elem.popular - nextelem.popular);
                    break;
                case 'rich':
                    data = [...data].sort((elem, nextelem) => nextelem.price - elem.price);
                    break;
                case 'poor':
                    data = [...data].sort((elem, nextelem) => elem.price - nextelem.price);
                    break;
                default:
                    break;
            }
        }

        if (search) {
            data = [...data].filter(elem =>
                elem.title.toLowerCase().includes(search.toLowerCase())
            );
        }
        setFilterData(data);
    }, [posts.data, type, filters, filterOptions, search]);

    useEffect(() => {
        if (posts.data) {
            const filteredData = posts.data.filter(item => item.type === type);

            const sizes = [];
            const colors = [];
            const availableSeasonsSet = new Set();

            filteredData.forEach(post => {
                post.sizes.forEach(size => {
                    if (!sizes.includes(size)) {
                        sizes.push(size);
                    }
                });
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
                if (post.season) { // Додаткова перевірка на null/undefined
                    availableSeasonsSet.add(post.season);
                }
            });

            const availableSeasons = Array.from(availableSeasonsSet);

            sizes.sort((a, b) => Number(a) - Number(b));
            colors.sort();

            setFilterOptions({
                sizes: sizes,
                colors: colors,
                seasons: availableSeasons
            });
            setLoading(false);
        }
    }, [posts.data, type]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: value
        }));
    };

    const handleResetFilters = () => {
        setFilters({
            selectSort: "default",
            selectSize: "default",
            selectSeason: "default",
            selectColor: "default"
        });
        setSearch("");
    };

    const handleRemoveSingleFilter = (filterName) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: "default"
        }));
    };

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        applyFilter();
    };

    const debouncedSearch = useMemo(
        () => debounce(handleSearchInputChange, 300),
        []
    );

    // SEO та розмітка даних
    const structuredData = useStructuredData(filterData || (status === 'success' && posts.data ? posts.data.filter(item => item.type === type) : null));

    const metaTitle = useMemo(() => "Купити Ботильйони Жіночі: Чорні Шкіряні на Масивній Підошві | Взуттєва Мрія", []);
    const metaDescription = useMemo(() => {
        let description = "Жіночі ботильйони в інтернет-магазині Взуттєва Мрія: чорні шкіряні ботильйони на масивній підошві, зручні стрейчові моделі. Великий вибір, доступні ціни, доставка по Україні.";
        if (filters.selectSize !== "default") {
            description += ` розміру ${filters.selectSize}`;
        }
        if (filters.selectSeason !== "default" && filters.selectSeason in seasonMap) {
            description += ` для сезону ${seasonMap[filters.selectSeason] || filters.selectSeason}`;
        }
        if (filters.selectColor !== "default") {
            description += ` в кольорі ${filters.selectColor}`;
        }
        if (search) {
            description += ` Знайдіть свої ідеальні ботильйони за запитом "${search}".`;
        }
        return description;
    }, [filters, search, seasonMap]);
    const metaKeywords = useMemo(() => "ботильйони, жіночі ботильйони, чорні ботильйони, шкіряні ботильйони, ботильйони на масивній підошві, стрейчові ботильйони, купити ботильйони Україна, взуттєва мрія", []);
    const headerTitle = useMemo(() => "Ботильйони Жіночі: Стильні Чорні Моделі", []);
    const headerText = useMemo(() => "Широка колекція жіночих ботильйонів з акцентом на чорні шкіряні моделі та ботильйони на масивній підошві. Оновіть свій гардероб вже сьогодні!", []);

    const totalProductsCount = useMemo(() => {
        return posts.data ? posts.data.filter(item => item.type === type).length : 0;
    }, [posts.data, type]);

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">{headerTitle}</h1>
                            <p className="header__text">{headerText}</p>
                        </div>
                        <img src={header_img} alt="Ботильйони жіночі" className="header__img" loading="lazy" />
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                    {/* Компонент фільтрів */}
                    <FilterPanel
                        filterOptions={filterOptions}
                        filterValues={filters}
                        onFilterChange={handleFilterChange}
                        onReset={handleResetFilters}
                        searchValue={search}
                        onSearchChange={debouncedSearch}
                        onSearchSubmit={handleSearchButtonClick}
                        seasonMap={seasonMap}
                    />

                    {/* Компонент активних фільтрів */}
                    <ActiveFilters
                        filters={filters}
                        filterOptions={filterOptions}
                        onReset={handleResetFilters}
                        onRemoveSingleFilter={handleRemoveSingleFilter}
                        seasonMap={seasonMap}
                    />

                    {/* Відображення кількості товарів */}
                    {status !== 'loading' && totalProductsCount > 0 && (
                        <ProductCount count={filterData.length} totalCount={totalProductsCount} />
                    )}

                    {/* Стан завантаження */}
                    {status === 'loading' && <Spiner />}

                    {/* Відображення товарів або порожнього стану */}
                    {status !== 'loading' && totalProductsCount > 0 && (
                        <>
                            {filterData.length > 0 ? (
                                <div className="goods__all" ref={el => { }}>
                                    <Pagination
                                        items={filterData}
                                        renderImage={(src, item) => ( // Передаємо item в renderImage
                                            <img
                                                src={src}
                                                alt={`Жіночі ботильйони: ${item.title}, ${item.color}, ${item.material}`}
                                                loading="lazy"
                                            />
                                        )}
                                    />
                                </div>
                            ) : (
                                <EmptyState
                                    message="Товари не знайдено за вашим запитом"
                                    actionText="Скинути фільтри"
                                    onAction={handleResetFilters}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Botilioni;