import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Nav, Footer, Pagination, Spiner } from '../../components/';
import header_img from '../../image/header-logo.webp';
import useStructuredData from '../../hooks/useStructuredData'; // Імпортуємо хук

const Sliponi = () => {
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const { posts, status } = useSelector(state => state.post);
    const type = "Сліпони";
    const [loading, setLoading] = useState(true);
    const [selectSort, setSelectSort] = useState("default");
    const [selectSize, setSelectSize] = useState("default");
    const [selectSeason, setSelectSeason] = useState("default");
    const [selectColor, setSelectColor] = useState("default");
    const [size, setSize] = useState([]);
    const [colors, setColors] = useState([]);
    const [hasSizes, setHasSizes] = useState(false);
    const [hasColors, setHasColors] = useState(false);
    const [hasSeasons, setHasSeasons] = useState(false);
    const [seasons, setSeasons] = useState([]);

    // Об'єкт зіставлення англійських назв сезонів з українськими
    const seasonMap = useMemo(() => ({
        "summer": "Літо",
        "winter": "Зима",
        "spring": "Весна-осінь"
    }), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

     const applyFilter = useCallback(() => {
         if (!posts.data) return; // Перевірка на наявність даних

        let data = posts.data.filter(elem => elem.type === type);

        if (selectSize !== "default" && hasSizes) {
            data = data.filter(elem => elem.sizes.map(Number).includes(parseInt(selectSize)));
        }
        if (selectSeason !== "default" && hasSeasons) {
            data = data.filter(elem => elem.season === selectSeason);
        }
        if (selectColor !== "default" && hasColors) {
            data = data.filter(elem => elem.color === selectColor);
        }

        if (selectSort !== "default") {
            switch (selectSort) {
                case 'popular':
                    data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
                    break;
                case 'unpopular':
                    data = [...data].sort((elem, nextelem) => elem.popular - nextelem.popular);
                    break;
                case 'rich':
                    data = [...data].sort((elem, nextelem) => nextelem.price - elem.price);
                    break;
                case 'poor':
                    data = [...data].sort((elem, nextelem) => elem.price - nextelem.price);
                    break;
                default:
                    break;
            }
        }
        setFilterData(data);
    }, [posts.data, type, selectSize, hasSizes, selectSeason, hasSeasons, selectColor, hasColors, selectSort]);

    const searchProduct = useCallback((value) => {
        if (!posts.data) return;

        let data = posts.data.filter(item => item.type === type);
        if (value) {
            data = [...data].filter(elem =>
                elem.title.toLowerCase().includes(value.toLowerCase())
            );
        }
        setFilterData(data); // Показуємо всі результати, якщо пошук пустий
    }, [posts.data, type]);

    useEffect(() => {
        if (posts.data) {
            const sizes = [];
            const colors = [];
            let seasonsSet = new Set();

            posts.data.filter(item => item.type === type).forEach(post => {
                post.sizes.forEach(size => {
                    if (!sizes.includes(size)) {
                        sizes.push(size);
                    }
                });
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
                seasonsSet.add(post.season);
            });

            const seasonsArray = Array.from(seasonsSet);

            setHasSizes(sizes.length > 0);
            setHasColors(colors.length > 0);
            setHasSeasons(seasonsArray.length > 0);

            sizes.sort((a, b) => a - b);
            colors.sort();
            setSeasons(seasonsArray);
            setSize(sizes);
            setColors(colors);
            setLoading(false);
        }
    }, [posts.data, type]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter, selectSort, selectSize, selectSeason, selectColor]);

    useEffect(() => {
        searchProduct(search);
    }, [search, searchProduct]);

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        searchProduct(search);
    };

    const deleteFilter = () => {
        setSelectSort("default");
        setSelectSize("default");
        setSelectSeason("default");
        setSelectColor("default");
        setSearch("");
    };

    const structuredData = useStructuredData(filterData || (status === 'success' && posts.data ? posts.data.filter(item => item.type === type) : null));

    const getDescription = useMemo(() => {
        let description = "Купити жіночі сліпони в інтернет-магазині Взуттєва Мрія. Великий вибір стильних моделей";

        if (selectSize !== "default") {
            description += ` розміру ${selectSize}`;
        }
        if (selectSeason !== "default" && selectSeason in seasonMap) {
            description += ` для сезону ${seasonMap[selectSeason]}`; // Використовуємо українську назву
        }
        if (selectColor !== "default") {
            description += ` в кольорі ${selectColor}`;
        }

        description += ". Доставка по Україні.";
        return description;
    }, [selectSize, selectSeason, selectColor, seasonMap]);

    return (
        <>
            <Helmet>
                <title>Купити Сліпони Жіночі в Україні | Взуттєва Мрія - Шкіряні, Текстильні, Літні</title>
                <meta name="description" content={getDescription} />
                <meta name="keywords" content="сліпони, купити сліпони, сліпони жіночі, сліпони шкіряні, сліпони текстильні, сліпони літні, сліпони ціна, сліпони Київ, сліпони Україна, інтернет-магазин взуття, взуття жіноче, сліпони з нубуку, сліпони з перфорацією" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Купити Сліпони Жіночі в Україні | Взуттєва Мрія - Шкіряні, Текстильні, Літні" />
                <meta property="og:description" content={getDescription} />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">Сліпони Жіночі: Зручність та Стиль</h1>
                            <p className="header__text">Відкрийте для себе ідеальну пару жіночих сліпонів в інтернет-магазині "Взуттєва Мрія". У нас ви знайдете шкіряні, текстильні та літні моделі на будь-який смак. Знайдіть свої ідеальні сліпони сьогодні!</p>
                        </div>
                        <img src={header_img} alt="Сліпони жіночі" className="header__img" loading="lazy" />
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                    <div className="filter">
                        <div className="filter__top">
                            <select name="sort" className="filter__sort" onChange={(event) => setSelectSort(event.target.value)} value={selectSort}>
                                <option value="default" disable="false" hidden>Сортувати за</option>
                                <option value="popular">Спочатку популярні</option>
                                <option value="unpopular">Спочатку не популярні</option>
                                <option value="rich">Спочатку дорогі</option>
                                <option value="poor">Спочатку дешеві</option>
                            </select>
                            {hasSizes && <select name="size" className="filter__size" onChange={(event) => setSelectSize(event.target.value)} value={selectSize}>
                                <option value="default" disable="false" hidden>Розмір</option>
                                {
                                    size.map((elem, index) => (
                                        <option key={index} value={elem}>{elem}</option>
                                    ))
                                }
                            </select>}
                           {hasSeasons && (
                                <select
                                    name="season"
                                    className="filter__season"
                                    onChange={(event) => setSelectSeason(event.target.value)}
                                    value={selectSeason}
                                >
                                    <option value="default" disable="false" hidden>Сезон</option>
                                    {seasons.map((season, index) => (
                                        <option key={index} value={season} >
                                            {seasonMap[season] || season} {/* Використовуємо українську назву */}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {hasColors && <select name="color" className="filter__color" onChange={(event) => setSelectColor(event.target.value)} value={selectColor}>
                                <option value="default" disable="false" hidden>Колір</option>
                                {
                                    colors.map((elem, index) => (
                                        <option key={index} value={elem}>{elem}</option>
                                    ))
                                }
                            </select>}
                        </div>
                        <div className="filter__bottom">
                            <button className="filter__delete" onClick={deleteFilter}>Скинути</button>
                            <div className="filter__search">
                                <button className="filter__search-btn" onClick={handleSearchButtonClick}></button>
                                <input type="text" className="filter__search-input" placeholder='Пошук' value={search} onChange={handleSearchInputChange} />
                            </div>
                        </div>
                    </div>
                    {loading ? <Spiner /> :
                        <Pagination items={filterData}
                            renderImage={(src) => (
                                <img src={src} alt="Сліпони" loading="lazy" />
                            )}
                        />
                    }
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Sliponi;